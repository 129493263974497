import React from 'react';
import Challenge from './Challenge'

// import challengeLinkBuilder from '../../utils/challengeLinkBuilder';

class Neighborhood extends React.Component {

  renderChallenges(category, neighborhood, challenges, student, neighborhoodComplete) {
    // Render all challenges of this neighborhood
    let sectionsGroup = []
    for (const [problemName, problem] of Object.entries(challenges)) {
      for (const [i, challenge] of Object.entries(problem.challenges)) {
        //console.log("c",challenge);
        sectionsGroup.push(
          <Challenge
            key={challenge.id}
            neighborhood={category}
            problem={challenge.problem}
            challenge={challenge}
            challengeNo={parseInt(challenge.order)}
            unlocked={challenge.unlocked}
            completed={challenge.completed}
            ultimate={neighborhoodComplete}
          />
        )
      };

    };
    // "aventura" neighborhood SVG render exception
    if (category === "descoberta") sectionsGroup.reverse()
    return sectionsGroup
  }

  render() {
    const category = this.props.category;
    const neighborhood = this.props.stats;
    const challenges = this.props.challenges;
    const student = this.props.student
    const neighborhoodComplete = neighborhood.completed;

    return (
      <g id={`Neighborhood--${category}`}>
        {this.renderChallenges(category, neighborhood, challenges, student, neighborhoodComplete)}
      </g>
    )
  }
}

export default Neighborhood;
