import React from 'react';

class CityCompleteAura extends React.Component {

  renderCompleteAura(complete){
    if(complete){
      return <path d="M514.41 33.032c266.309 0 482.518 216.209 482.518 482.518 0 266.309-216.209 482.518-482.518 482.518-266.309 0-482.518-216.209-482.518-482.518 0-266.309 216.209-482.518 482.518-482.518zM512.3 44.86c260.333 0 471.69 211.357 471.69 471.69 0 260.333-211.357 471.69-471.69 471.69-260.333 0-471.69-211.357-471.69-471.69 0-260.333 211.357-471.69 471.69-471.69z" fill="rgb(118,206,162)" transform="translate(-1.25764 -32.9576) scale(.99776)" id="Complete-aura"/>
    } else {
      return null
    }
  }

  render() {
    const gameComplete = (this.props.gameComplete);

    // Complete Aura (Green circle that appears around the city when every challenge completed)
    return (
      <g>
        {this.renderCompleteAura(gameComplete)}
      </g>
    )
  }
}

export default CityCompleteAura;