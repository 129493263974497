import React from "react"
import returnIcon from "../assets/images/icon-left.svg"
import Button from "../components/game/appButton.js";
import TopBar from "../components/dashboard/topbar.js";
import CategoryIcon from "../components/dashboard/categoryIcon.js";
import { Link } from "react-router-dom";
import { firestore } from "../middlewares/firebase";
import { TeacherDataContext } from "../providers/TeacherDataProvider";
import Loader from "../components/game/loader";
import { parseChallengeType } from "../utils/funcs"

class StudentAnswers extends React.Component {
  static contextType = TeacherDataContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      student: null,
      submissions: null,
      selectedChallenge: null,
    }
  }

  async componentDidMount() {
    let studentId = this.props.match.params.studentId;
    let student = (await firestore.collection('students')
                    .doc(studentId).get()).data();

    let submissions = (await firestore.collection(`students/${studentId}/answers`)
                        .get()).docs.map(doc => doc.data());

    submissions = submissions.sort((a, b) => (a.level - b.level) || ((a.problem > b.problem) ? 1 : ((a.problem < b.problem) ? -1 : 0)) || ((a.order > b.order) ? 1 : -1))

    this.setState({
      loading: false,
      student,
      submissions
    });
  }

  renderAnswer(answer) {
    if(answer.type === "file") {
      return (<a
        className="o-dashboard__text-link"
        href={answer.value} target="_blank" rel="noreferrer">
        descarregar ficheiro
      </a>)
    } else {
      return (<p>{answer.value}</p>)
    }
  }

  setSelectedChallenge(index) {
    this.setState({selectedChallenge: index})
  }
  render() {

    if(this.state.loading){
      return <Loader />
    }

    return(
      <div className="o-dashboard-container">
        <TopBar
          teacherName={this.context.teacher.displayName}
          activeClassName={this.context.activeClass.name}
        />
        <div className="o-dashboard o-dashboard--slim">
          <section className="o-dashboard__section u-pt-0 u-pb-0">
            <div className="o-dashboard__container u-pb-0 u-mt-3x">
              <div data-flout="row-- align-start--">
                <Link
                  className="o-dashboard__topbar-link"
                  to="/teacher-dashboard"
                >
                  <Button wrapperModifiers="c-button-wrapper--previous u-mt u-mt-0@sm">
                    voltar à turma
                  </Button>
                </Link>
              </div>
            </div>
          </section>

          <section className="o-dashboard__section u-pt-0 u-pb-0">
            <div className="o-dashboard__container u-pb-0 u-mt-2x">
              <div  data-flout="row-- align-center--" data-flitem="grow--">
                <div className="o-dashboard__class-badge">
                  <p className='o-dashboard__class-badge-text'>{this.context.activeClass.name}</p>
                </div>
                <h2 className='u-ml-2x u-tw-600 c-title c-title--dashboard'>{this.state.student.name} | Nº{this.state.student.studentNo}</h2>
              </div>
            </div>
          </section>


          {/* STUDENTS VIEW*/}
          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <div className="o-grid" data-flout="row--">
                <div className="o-grid__col u-12/12">
                  <div className="o-table-wrapper">
                    <table className="o-table o-table--missions">
                      <thead className="o-table__head">
                        <tr className="o-table__headings  o-table__headings--missions">
                          <th className="o-table__field o-table__cell--filled o-table__cell--tr-str u-text-l">DESAFIO</th>
                          <th className="o-table__field o-table__cell--filled o-table__cell--tr-end u-text-r">RESPOSTAS</th>
                        </tr>
                      </thead>
                      <tbody className="o-table__body">
                        {this.state.submissions.map((submission, key) =>

                          <React.Fragment key={key}>
                          {console.log("RESPOSTA", submission)}
                            <tr><th className="u-pt-2x"></th></tr>
                            <tr className={`o-table__row o-table__row--collapsable-head ${this.state.selectedChallenge === key ? "uncollapsed" : "o-table__row--missions"}`}>
                              <td className="o-table__cell o-table__cell--filled o-table__cell--tr-str u-text-l">
                                <div data-flout="row-- align-center--">
                                  <CategoryIcon category={submission.type} />
                                  <div className="u-ml">
                                    <p className="u-tw-600">{parseChallengeType(submission.type)} {submission.title ? '- "'+submission.title+'"' : ""}</p>
                                    <p>Bairro {submission.level} - Nível {submission.problem} </p>
                                  </div>
                                </div>
                              </td>
                              <td className="o-table__cell o-table__cell--filled o-table__cell--tr-end o-table__cell--grow-xs u-text-r">
                                <label
                                className={`u-mr-3x ${this.state.selectedChallenge !== key ? "o-table__trigger" : "o-table__trigger o-table__trigger--open"}`}
                                onClick={
                                  this.state.selectedChallenge !== key ?
                                  () => this.setSelectedChallenge(key) :
                                  () => this.setSelectedChallenge(null)
                                }>
                                {this.state.selectedChallenge !== key ?
                                  "ver respostas" : "esconder respostas"
                                }
                                </label>
                              </td>
                            </tr>
                            <tr className={`o-table__row o-table__row--collapsable ${this.state.selectedChallenge === key ? "uncollapsed" : "o-table__row--missions"}`}>
                              <td className="o-table__cell o-table__cell--collapsable u-text-l" colSpan={2}>
                                <div className="o-table__collapse-content">
                                  {/*START HERE DO THE LOOP TROUGH THE QUESTIONS*/}
                                  {submission.answers.sort((a, b) => parseInt(a.order) - parseInt(b.order)).map((answer, key) =>
                                    <React.Fragment key={key}>
                                      <div className="o-table__qa-block">
                                        <p className="o-table__qa-label">pergunta:</p>
                                        <p className="u-mb-2x">{answer.label}</p>
                                        <p className="o-table__qa-label">resposta:</p>
                                        {this.renderAnswer(answer)}
                                      </div>
                                      <hr className="o-table__qa-divider"/>
                                    </React.Fragment>
                                  )}
                                  {/*END HERE DO THE LOOP TROUGH THE QUESTIONS*/}
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
    )
  }
}


export default StudentAnswers
