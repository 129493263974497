import React from "react"
import { Link } from "react-router-dom"
import Loader from '../components/game/loader'
import TopBar from "../components/dashboard/topbar.js"
import LogosTile from "../components/dashboard/logosTile.js"
import PageNavigation from "../components/level/pageNavigation.js"
import Game from '../components/city/main.js'
import { PageContext } from "../providers/PageProvider.js"
import { StudentDataContext } from "../providers/StudentDataProvider.js"
import { findProblemStatusInLevel, parseGameVersion } from "../utils/funcs"
import { ACTION_PANEL } from '../utils/constants'
import amuletGlobal from "../assets/images/amulets/amuletos.svg"
import amuletAlpha from "../assets/images/amulets/amuleto-alpha.svg"
import amuletRed from "../assets/images/amulets/amuleto-meraki.svg"
import amuletBlue from "../assets/images/amulets/amuleto-rosetta.svg"
import amuletGreen from "../assets/images/amulets/amuleto-chimera.svg"
import neighborhoodAfetos from "../assets/images/bairroAfetos.svg"
import neighborhoodAventura from "../assets/images/bairroAventura.svg"
import neighborhoodCausas from "../assets/images/bairroCausas.svg"

class Level extends React.Component {
  static contextType = StudentDataContext;

  constructor(props) {
    super(props);
    this.pageBottom = React.createRef();
  }

  scrollToBottom(){
    // On Desktop, scroll to the bottom of the page if any challenge
    // is just completed (including tutorial)
    if(this.props.location.state?.anyCompleted && window.innerWidth > 768) {
      setTimeout(() => {
        this.pageBottom.current.scrollIntoView({behavior: "smooth"})
      }, 500)
    }
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  render() {
    if(this.context.loading){
      return <Loader />
    }
    const levels = this.context.student.gameState.levels
    const amulets = [
      {
        name: "Alpha",
        img: amuletAlpha,
        completed: this.context.student.gameState.amulets.alpha,
        text_locked: "Para ganhares este amuleto tens que abrir as portas da Cidade de Logos",
        text_unlocked: "Alpha | Por teres arriscado entrar em Logos, tens a proteção deste amuleto!"
      },
      {
        name: "Meraki",
        img: amuletRed,
        completed: this.context.student.gameState.amulets.meraki,
        text_locked: "Para ganhares este amuleto tens que realizar todos os desafios do 1º anel da Cidade de Logos",
        text_unlocked: "Meraki | Tens a força da palavra, usa-a bem!"
      },
      {
        name: "Rosetta",
        img: amuletBlue,
        completed: this.context.student.gameState.amulets.rosetta,
        text_locked: "Para ganhares este amuleto tens que realizar todos os desafios do 2º anel da Cidade de Logos",
        text_unlocked: "Rosetta | Ganhaste o poder da descodificação!"
      },
      {
        name: "Chimera",
        img: amuletGreen,
        completed: this.context.student.gameState.amulets.chimera,
        text_locked: "Para ganhares este amuleto tens que realizar todos os desafios do 3º anel da Cidade de Logos",
        text_unlocked: "Chimera | Tens o dom de transformar a realidade!"
      }
    ]
    const neighborhoods = [
      {
        name: "Bairro dos Afetos",
        img: neighborhoodAfetos,
        text: "Onde o que as pessoas sentem nunca fica por dizer"
      },
      {
        name: "Bairro das Causas",
        img: neighborhoodCausas,
        text: "Onde as pessoas lutam por aquilo em que acreditam"
      },
      {
        name: "Bairro da Aventura",
        img: neighborhoodAventura,
        text: "Onde não há limites para a imaginação"
      }
    ]

    let allChallenges = this.context.challenges;
    let challenges = {};

    Object.keys(levels).forEach((key, i_l) => {
      let level = levels[key];

      Object.assign(challenges, { [level.order]: {} });

      level.problems.forEach((problem, i_p) => {

        let problemStatus =  findProblemStatusInLevel(levels[level.order].problems, problem.name);

        challenges[level.order][problem.name] = {
          finished: problemStatus.completed,
          challenges: {}
        };

        problemStatus.challenges.forEach((challengeStatus, i_c) => {
          //console.log(level);
          //console.log(problem.name);

          let challenge =  allChallenges[level.order][problem.name].filter(c => c.id === challengeStatus.id)[0];

          if(problemStatus.unlocked) {
            if(i_c === 0) {
              challenge.unlocked = true
            } else {
              challenge.unlocked = problemStatus.challenges[i_c - 1].completed
            }
          } else {
            challenge.unlocked = false
          }

          challenge.completed = problemStatus.challenges[i_c].completed;
          challenge.hidden = problemStatus.challenges[i_c].hidden;

          challenges[level.order][problem.name]['challenges'][challenge.order] = challenge;
        });

      });

      console.log(challenges);

    });

    return (
      <div className="o-dashboard-container">
        <TopBar
          studentName={this.context.student.name}
          connectedCitizens={this.context.student.gameState.scoreboard.connectedCitizens * 10}
          destroyedWalls={this.context.student.gameState.scoreboard.destroyedWalls}
        />
        <div className="o-dashboard">
          <div className="o-dashboard__city">
            <section className="o-dashboard__section u-pt-0">
              <div className="o-dashboard__container">
                <div className="o-grid" data-flout="col--row-reverse@sm--">
                  <div className="o-grid__col u-12/12 u-6/12@sm" data-flout="col-- align-center--">
                    <LogosTile modifiers="c-tile-logos--sm"/>
                    <div className="o-level-game">
                      <Game
                        challenges={challenges}
                        student={this.context.student}
                      />
                    </div>
                  </div>
                  <div className="o-level-sections-group o-grid__col u-12/12 u-6/12@sm" data-flout="col-- align-center--">
                    <div className="o-level-section">
                      <div className="o-level-section__title" data-flout="justify-center-- nowrap-- align-center--">
                        <img src={amuletGlobal} alt="Icon dos Amuletos" className="o-level-section__icon-title u-mr-2x" />
                        <p>Amuletos</p>
                      </div>
                      {amulets.map((amulet, index) => (
                        <div key={`amulets-description--${index}`}  className={`${index == amulets.length-1 ? "u-mb" : "u-mb-4x"}`} data-flout="nowrap-- align-center--">
                          <img src={amulet.img}
                            alt={`Icon do Amuleto ${amulet.name}`}
                            className={`o-level-section__icon u-mr ${ amulet.completed ? "" : "o-level-section__icon--disabled"}`}
                          />
                          <p>{amulet.completed ? amulet.text_unlocked : amulet.text_locked}</p>
                        </div>
                      ))}
                    </div>
                    <div className="o-level-section">
                      <div className="o-level-section__title o-level-section__title--secondary">
                        <p className="u-tw-600">Explora os bairros de Logos,</p>
                        <p>derruba os seus muros e aproxima os seus cidadãos!</p>
                      </div>
                      <div className="o-grid" data-flout="nowrap-- row--">
                        {neighborhoods.map((neighborhood, index) => (
                          <div key={`neighborhood-description--${index}`}  className="o-grid__col u-4/12 u-mb-0" data-flout="col-- align-center--">
                            <img src={neighborhood.img} alt={`Icon do ${neighborhood.name}`} />
                            <p className="u-text-c u-mt o-level-description">{neighborhood.text}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </div>
        </div>
      </div>
    )
  }
}

export default Level
