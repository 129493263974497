import * as React from "react"

import LogoSpotgames from '../../assets/images/logos/spotgames.png';
import LogoInovacaoSocial from '../../assets/images/logos/pt-inovacao-social.png';

class AppFooter extends React.Component {

  renderNote(note){
    if(note) return (
      <p className="o-footer__note">{note}</p>
    )
  }

  renderLogos(isTeacher) {
    return (
      <div data-flout="justify-center--">
        <img className="o-footer__logo" src={LogoSpotgames} alt="SpotGames" />
        <img className="o-footer__logo" src={LogoInovacaoSocial} alt="Portugal Inovação Social" />
      </div>
    )
  }

  render() {
    return (
      <footer className="o-footer">
        {this.renderNote(this.props.note)}
        {this.renderLogos(this.props.isTeacher)}
      </footer>
    )
  }
}

export default AppFooter
