import * as React from "react"

import Baloon from '../../assets/images/background-baloon-title.svg';

function themeClass(modifiers){
  return `c-title ${modifiers ? modifiers : ""}`
}

function Title(props) {
  return (
    <h1 className={themeClass(props.modifiers)}>
      {props.content}
    </h1>
  )
}

export default Title
