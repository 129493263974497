import * as React from "react"

function themeClass(modifiers) {
  return `c-button ${modifiers ? modifiers : ""}`
}

function wrapperClass(modifiers) {
  return `c-button-wrapper ${modifiers ? modifiers : ""}`
}

function AppButtonIcon (props) {

  /* PROPS

    modifiers: [CSS classes]
    verifyIcon: [verify icon on the top-right corner]
    disabled: [disable the button as native HTML]
    onClick: [function to trigger on button click]

  ---- */

  return (
    <div className={wrapperClass(props.wrapperModifiers)}>
      <button
        className={themeClass(props.modifiers)}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </div>
    )
}

export default AppButtonIcon
