import React from 'react';
import { sectionsShape } from '../../utils/sectionShapes';
import { Link, withRouter } from 'react-router-dom';

class Challenge extends React.Component {

  challengesStyles(neighborhood, unlocked, completed) {
    // Determine title CSS classes according to the game state
    let baseClasses = "c-game__challenge c-game__challenge--" + neighborhood
    if (unlocked) {
      if (completed) {
        return baseClasses + " c-game__challenge--completed"
      }
      return baseClasses + " c-game__challenge--unlocked"
    } else
      return baseClasses
  }

  render() {
    const neighborhood = this.props.neighborhood;
    const problem = this.props.problem;
    const challenge = this.props.challengeNo;
    const unlocked = this.props.ultimate || this.props.unlocked;
    const completed = this.props.completed;
    const link = this.props.link;
    const ultimate = this.props.ultimate;

    let shape = sectionsShape[neighborhood][problem][challenge];
    // v delete this when correct challenges are loaded
    if (shape){
    // ^ delete this when correct challenges are loaded
    return (
      <Link to={{
        pathname: '/desafio',
        state: {
          challenge: this.props.challenge
        }
      }}>

        <linearGradient id="causas-gradient" x2="1" y2="1">
          <stop offset="30%" stopColor="#3B312B" />
          <stop offset="100%" stopColor="#175A6C" />
        </linearGradient>
        <linearGradient id="aventura-gradient" x2="1" y2="1">
          <stop offset="30%" stopColor="#3B312B" />
          <stop offset="100%" stopColor="#BF792A" />
        </linearGradient>
        <linearGradient id="afetos-gradient" x2="1" y2="1">
          <stop offset="30%" stopColor="#3B312B" />
          <stop offset="100%" stopColor="#9A8E42" />
        </linearGradient>

        <g className={this.challengesStyles(neighborhood, unlocked, completed) + (challenge === "4" ? " c-game__challenge--ultimate" : "")}>
          <path
            className="c-game__challenge-bg"
            d={shape["background"]["d"]}
            transform={shape["background"]["transform"]}
          />
          <path
            className="c-game__challenge-deco"
            d={shape["decorations"]["d"]}
            transform={shape["decorations"]["transform"]}
          />
          {ultimate ? "" : <path
            className={completed ? "c-game__challenge-ppl-completed" : "c-game__challenge-ppl"}
            d={shape[completed ? "people_completed" : "people_base"]["d"]}
            transform={shape[completed ? "people_completed" : "people_base"]["transform"]}
          />}

        </g>
      </Link>
    )
    // v delete this when correct challenges are loaded
    } else {
      return ""
    }
    // ^ delete this when correct challenges are loaded
  }
}

export default Challenge;
