import React from 'react';
import { Redirect } from 'react-router-dom'
import Input from '../components/game/appInput.js';
import Select from '../components/game/appSelect.js';
import ButtonIcon from '../components/game/appButtonIcon.js';
import LoginImage from '../assets/images/logos/logos-icon.svg'
import AppFooter from '../components/game/appFooter.js';
import { parseGameVersion } from "../utils/funcs"
import { firestore, studentSignIn } from '../middlewares/firebase';
import Loader from '../components/game/loader.js';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      class: null,
      classId: null,
      confirmDisabled: true,
      studentsId: [...Array(40).keys()].map(i => i+1),
      studentNo: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let classQuery = await firestore
      .collection("classes")
      .where("url", "==", this.props.match.params.slug)
      .get();

    // Sanity check to ensure we are getting a valid class
    let validClasses = classQuery.docs.filter(doc => doc.id !== "null");

    if(classQuery.size === 0){
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        loading: false,
        class: validClasses[0].data(),
        classId: validClasses[0].id
      });
    }
  }


  handleChange(event) {
    this.setState({
      studentNo: event.target.value,
      confirmDisabled : false
    });
  }

  handleSubmit(event) {
    this.setState({
      loading: true
    });

    studentSignIn(this.state.classId, this.state.studentNo);
    event.preventDefault();
  }

  showCufLogo(version) {
    if(parseGameVersion(version) === "cuf-saude") {
      return true
    } else {
      return false
    }
  }

  render() {
    if(this.state.loading) {
      return <Loader />
    }

    if(!this.state.class) {
      return <Redirect to="/" />
    }

    return (
      <div className="o-app__bg-land">
        <div className="o-app__login">
          <div className="o-app__frame o-app__frame--lg">
            <section className="o-view">
              <div className="o-app__container o-app__container--lg o-app__container--login" data-flout="justify-center-- expand--">
                <img
                  src={LoginImage}
                  alt="Login no SPOT Cidadania"
                  className="c-image-login"
                />
                <div className="u-width-auto">
                  <div className="o-grid@md" data-flout="col-- align-center--">
                    <Input
                      id="school-name"
                      value={this.state.class.school}
                      readOnly={true}
                      placeholder="Nome da escola"
                      modifiers="u-mb u-mb-2x@sm o-grid__col u-12/12 u-pl-0"
                    />
                    <Input
                      id="class"
                      value={this.state.class.name}
                      readOnly={true}
                      placeholder="Turma"
                      modifiers="u-mb u-mb-2x@sm o-grid__col u-12/12 u-pl-0"
                    />
                    <Select
                      id="student-number"
                      options={this.state.studentsId}
                      value={this.state.studentNo}
                      onChange={this.handleChange}
                      placeholder="Número de Turma"
                      modifiers="u-mb u-mb-2x@sm o-grid__col u-12/12 u-pl-0"
                    />
                    <ButtonIcon 
                      onClick={this.handleSubmit} 
                      modifiers="u-mb u-mb-2x@sm o-grid__col u-pl-0" 
                      disabled={this.state.confirmDisabled} 
                    >
                      Login
                    </ButtonIcon>
                  </div>
                </div>
              </div>
              <div className="o-app__container o-app__container--lg u-mt-2x" data-flout="justify-end-- expand--">
                <AppFooter
                  note="Se souberes de um amigo que não se pode registar, convida-o para jogar contigo."
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
