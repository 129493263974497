import React from "react"
import Challenge from "../components/challenge/modal.js"
import { auth, getStepsForChallenge, submitAnswer, firestore } from '../middlewares/firebase';

class ChallengesView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge: {},
      selectedChallenge: 0,
      selectedChallengeAnswers: null,
      stepsForModal: [],
      showChallenge: false,
      activeStep: 0,
      activeStepAnswer: "",
      answerSubmitted: false,
    }

    this.submitChallengeAnswer = this.submitChallengeAnswer.bind(this);
  }

  async componentDidMount() {

    this.setState({
      challenge: this.props.location.state.challenge
    }, () => {
      this.setSelectedChallenge(this.state.challenge.order);
      this.openChallenge()
    })
  }

  componentDidUpdate(nextProps) {
    // // Redirect to the level page if the last challenge is completed
    // if(this.state.answerSubmitted && !this.state.showChallenge) {
    //   if(this.state.selectedChallenge + 1 === this.props.challenges.length) {
    //     this.closeChallenge();
    //   } else {
    //     // Reset the answerSubbmited to false if the problem isn't completed
    //     this.setSelectedChallenge(this.state.selectedChallenge + 1)
    //     this.setState({ answerSubmitted: false, challengesUpdated: false })
    //   }
    // }
  }

  setSelectedChallenge(index) {
    this.setState({selectedChallenge: index}, async () => {
      let {level, problem} = this.state.challenge;

      let submissions = (await firestore.collection(`students/${auth.currentUser.uid}/answers`)
      .get()).docs.map(doc => doc.data())
      .filter((submission) => submission.level === level)
      .filter((submission) => submission.problem === problem);

      let selectedChallengeAnswers = null;

      if (this.state.challenge.completed){
        selectedChallengeAnswers = submissions.filter((submission) => submission.order === this.state.selectedChallenge.toString())[0].answers
      }

      this.setState({ selectedChallengeAnswers: selectedChallengeAnswers })

    })
  }

  getActiveStepAnswer(){
    let activeStepAnswer = ""
    if (this.state.challenge.completed) {
      let questionSteps = this.state.stepsForModal.filter((step) => step.type === "question").sort((a, b) => (parseInt(a.order) - parseInt(b.order)))
      let activeStep = this.state.stepsForModal[this.state.activeStep]
      if (activeStep.type === "question") {
        let activeStepAnswerIndex = questionSteps.findIndex((step, i) => step === activeStep)

        if (activeStepAnswerIndex > 0) {
          activeStepAnswer = this.state.selectedChallengeAnswers.sort((a, b) => (parseInt(a.order) - parseInt(b.order)))[activeStepAnswerIndex]
        } else {
          activeStepAnswer = this.state.selectedChallengeAnswers.sort((a, b) => (parseInt(a.order) - parseInt(b.order)))[0]
        }
      } else {
        activeStepAnswer = "";
      }
    }
    this.setState({ activeStepAnswer: activeStepAnswer ? activeStepAnswer.value : "" });
  }

  closeChallenge() {
    this.setState({ showChallenge: false , activeStep: 0})
    this.props.history.push({
      pathname: '/nivel'
    })
  }
  // Increase challenge step
  nextStep() {
    let nextActiveStep = this.state.activeStep + 1;
    //when challenge complete and next step not last and next step type === submit
    if (this.state.challenge.completed
        && nextActiveStep < this.state.stepsForModal.length - 1
        && this.state.stepsForModal[nextActiveStep].type === "submit") {
      // skip submit step
      nextActiveStep = this.state.activeStep + 2 ;

    }
    this.setState({ activeStep: nextActiveStep }, () => {
        this.getActiveStepAnswer()
    })
  }
  // Decrease challenge step
  previousStep() {
    let previousActiveStep = this.state.activeStep - 1;
    //when challenge complete and previous step not last and previous step type === submit
    if (this.state.challenge.completed
        && previousActiveStep < this.state.stepsForModal.length - 1
        && this.state.stepsForModal[previousActiveStep].type === "submit") {
      // skip submit step
      previousActiveStep = this.state.activeStep - 2 ;

    }
    this.setState({ activeStep: previousActiveStep }, () => {
        this.getActiveStepAnswer()
    })
  }

  submitChallengeAnswer(answers) {
    this.setState({ answerSubmitted: true })
    return submitAnswer(
      auth.currentUser.uid,
      this.state.challenge.level,
      this.state.challenge.problem,
      this.state.challenge,
      answers
    )
  }

  async openChallenge() {
    let challenge = this.state.challenge
    let steps = await getStepsForChallenge(challenge.id);

    this.setState({
      stepsForModal: steps,
      showChallenge: true,
      activeStep: 0
    }, () => this.getActiveStepAnswer());
  }

  render() {
    return(
      <div className="o-app__bg-paper">
        <Challenge
          challenge={this.state.challenge}
          steps={this.state.stepsForModal}
          onAnswerSubmit={this.submitChallengeAnswer}
          isOpen={this.state.showChallenge}
          closeChallenge={() => this.closeChallenge()}
          activeStep={this.state.activeStep}
          activeStepAnswer={this.state.activeStepAnswer}
          nextStep={() => this.nextStep()}
          previousStep={() => this.previousStep()}/>}
      </div>
    )
  }
}


export default ChallengesView
