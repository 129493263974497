import React from 'react';

class BadgesBackgrounds extends React.Component {
  render() {
    // Backgrounds for all the badges
    return (
      <g id="AMULETO-Background" transform="translate(-1.25764 -32.9576) scale(.99776)">
        <circle cx="383.91" cy="603.26" r="15.08" fill="rgb(61,52,47)"/>
        <circle cx="514.68" cy="360.55" r="15.08" fill="rgb(61,52,47)"/>
        <circle cx="643.39" cy="607" r="15.08" fill="rgb(61,52,47)" transform="translate(.430987 -4.87534)"/>
        <circle cx="276.9" cy="686.18" r="19.61" fill="rgb(61,52,47)"/>
        <circle cx="514.28" cy="224.29" r="19.61" fill="rgb(61,52,47)"/>
        <circle cx="751.21" cy="685.18" r="19.61" fill="rgb(61,52,47)"/>
        <circle cx="862.27" cy="782.61" r="25.12" fill="rgb(61,52,47)"/>
        <circle cx="157.53" cy="771.39" r="25.12" fill="rgb(61,52,47)"/>
        <circle cx="516.88" cy="74.07" r="25.12" fill="rgb(61,52,47)"/>
      </g>
    )
  }
}

export default BadgesBackgrounds;