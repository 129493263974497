import * as React from "react"

class Stepper extends React.Component {
  render() {
    const stepperItems = []
    for (let i = 0; i <= this.props.numSteps - 1; i += 1) {
      stepperItems.push(<div className={`${this.props.currentStep >= i ? "o-stepper__bar__step--filled" : "o-stepper__bar__step"} ${this.props.currentStep === i ? "o-stepper__bar__step--active" : ""}`} key={i} />)
    }
    return(
      <div className="o-stepper">
        <div className="o-stepper__bar">
          {stepperItems}
        </div>
        <label className="o-stepper__label">{this.props.currentStep + 1} de {this.props.numSteps}</label>
      </div>
    )
  }
}

export default Stepper
