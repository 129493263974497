import React from "react"
import { Link } from "react-router-dom"
import Button from "../components/game/appButton.js"
import Title from "../components/game/title.js"
import TopBar from "../components/dashboard/topbar.js"
import LogosTile from "../components/dashboard/logosTile.js"
import TerminateModal from "../components/dashboard/confirmationModal.js"
import Missions from "../components/dashboard/missions.js"
import logosBox from "../assets/images/caixa-logos.svg"
import mapLevels from "../assets/images/map-city-levels.svg"
import amuletMeraki from "../assets/images/amulets/amuleto-meraki.svg"
import amuletRosetta from "../assets/images/amulets/amuleto-rosetta.svg"
import amuletChimera from "../assets/images/amulets/amuleto-chimera.svg"
import dollCitizens from "../assets/images/doll-citizens.svg"
import areaChallenge from "../assets/images/area-challenge.svg"
import availableSoonPdf from "../assets/docs/pdf-brevemente.pdf"
import { closeClass } from "../middlewares/firebase";
import { TeacherDataContext } from "../providers/TeacherDataProvider";
import { CLASS_STATUS } from "../utils/constants"
import neighborhoodAfetos from "../assets/images/bairroAfetos.svg"
import neighborhoodAventura from "../assets/images/bairroAventura.svg"
import neighborhoodCausas from "../assets/images/bairroCausas.svg"

class TeacherDashboard extends React.Component {
  static contextType = TeacherDataContext;

  constructor(props) {
    super(props);
    this.state = {
      terminateModalOpen: false,
      manualGamemasterPdf: "",
      matrizPedagogicaPdf: "",
      desafiosSolucoesPdf: "",
      formatoClassicoPdf: "",
      formatoRemotoPdf: "",
      formatoContraRelogioPdf: "",
      copySuccess: "",
      closingClass: false,
      video1: "https://www.youtube.com/embed/V3BBS4hFS50",
      video2: "https://www.youtube.com/embed/4Q1929-NV9c",
      video3: "https://www.youtube.com/embed/oBM6BwlM1Uo",
      video4: "https://www.youtube.com/embed/XfRJ_f4-8IQ",
      video5: "https://www.youtube.com/embed/nB0bX44PhKo",
      video6: "https://www.youtube.com/embed/4qMmr_E6tTY"
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  copyToClipboard = (e) => {
    this.registerURL.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccess: "link copiado!" });
    setTimeout(function(){
      this.setState({copySuccess: ""});
    }.bind(this),1500);
  }

  terminateGame = (e) => {
    this.setState({ closingClass: true })
    closeClass(this.context.classId).then(() => {
      this.setState({ closingClass: false })
    });
    e.preventDefault();
  }


  toggleModal(value) {
    this.setState({ terminateModalOpen: value})
  }

  shortenStudentName(fullName) {
    let shortenedName = fullName;
    let names = fullName.split(" ");

    if (names.length > 2) { // bigger than 2 names: first two + last one
      shortenedName = `${names[0]} ${names[1]} ${names[names.length-1]}`
      if (shortenedName.length > 15) { // too long: first and last only
        shortenedName = `${names[0]} ${names[names.length-1]}`
      }
      return shortenedName;
    } else {
      return fullName;
    }
  }

  componentDidMount() {
    import(`../assets/docs/matriz-pedagogica-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({matrizPedagogicaPdf: doc.default})})
    import(`../assets/docs/desafios-solucoes-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({desafiosSolucoesPdf: doc.default})})
    if (this.context.activeClass.version === "cuf-saude"){
      import(`../assets/docs/manual-gamemaster-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({manualGamemasterPdf: doc.default})})
      import(`../assets/docs/formato-classico-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoClassicoPdf: doc.default})})
      import(`../assets/docs/formato-remoto-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoRemotoPdf: doc.default})})
      import(`../assets/docs/formato-contra-relogio-${this.context.activeClass.version}.pdf`).then(doc => {this.setState({formatoContraRelogioPdf: doc.default})})
    } else {
      import(`../assets/docs/manual-gamemaster.pdf`).then(doc => {this.setState({manualGamemasterPdf: doc.default})})
      import(`../assets/docs/formato-classico.pdf`).then(doc => {this.setState({formatoClassicoPdf: doc.default})})
      import(`../assets/docs/formato-remoto.pdf`).then(doc => {this.setState({formatoRemotoPdf: doc.default})})
      import(`../assets/docs/formato-contra-relogio.pdf`).then(doc => {this.setState({formatoContraRelogioPdf: doc.default})})
    }
  }

  render() {
    let crewGameURL = `${window.location.origin}/turmas/${this.context.activeClass.url}`;

    const neighborhoods = [
      {
        name: "Bairro dos Afetos",
        img: neighborhoodAfetos,
        text: "Onde o que as pessoas sentem nunca fica por dizer"
      },
      {
        name: "Bairro das Causas",
        img: neighborhoodCausas,
        text: "Onde as pessoas lutam por aquilo em que acreditam"
      },
      {
        name: "Bairro da Aventura",
        img: neighborhoodAventura,
        text: "Onde não há limites para a imaginação"
      }
    ]

    const amulets = [
      {
        code: "meraki",
        name: "Meraki",
        img: amuletMeraki,
        text: "Nível 1: Vocabulário, Correção Ortográfica e Pontuação"
      },
      {
        code: "rosetta",
        name: "Rosetta",
        img: amuletRosetta,
        text: "Nível 2: Construção de Frases, Coesão e Coerência Textual"
      },
      {
        code: "chimera",
        name: "Chimera",
        img: amuletChimera,
        text: "Nível 3: Construção e Organização de Parágrafos, Produção de Um Texto"
      }
    ]

    const otherChallenges = [
      {
        quantity: "6",
        text: "Aventuras para ler e interpretar"
      },
      {
        quantity: "5",
        text: "Desafios de pesquisa e organização de informação"
      },
      {
        quantity: "8",
        text: "Desafios com produção de elementos multimédia"
      },
      {
        quantity: "6",
        text: "Desafios de adoção de práticas sustentáveis"
      },
      {
        quantity: "4",
        text: "Desafios com pares, família ou comunidade"
      }
    ]

    return(
      <div className="o-dashboard-container">
        <TopBar
          teacherName={this.context.teacher.displayName}
          activeClassName={this.context.activeClass.name}
        />
        <div className="o-dashboard o-dashboard--slim">
          <section className="o-dashboard__section u-pt-0 u-pb-0">
            <div className="o-dashboard__container u-pb-0">
              <LogosTile modifiers="c-tile-logos--xs c-tile-logos--m-auto"/>
              <div data-flout="row-- align-start--">
                <Button wrapperModifiers="c-button-wrapper--previous u-mt u-mt-0@sm" onClick={(e) => this.context.classLogout()}>
                  mudar de turma
                </Button>
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <div className="o-grid" data-flout="row-- align-bottom-- justity-around--">
                <div className="o-grid__col u-12/12  u-7/12@sm u-mb">
                  <Title modifiers="c-title--dashboard" content="Bem-vind@ Gamemaster!"/>
                  <div className="u-mt-6x u-ml-3x">
                    <p className="u-mb-2x">
                      Damos-lhe as boas vindas à Liga de <span className="u-tw-700">Professores e Professoras Gamemasters</span>, um movimento nacional, onde irá encontrar outros professores que, à sua semelhança, têm como símbolo de superação, a <span className="u-tw-700 ">superação dos seus alunos</span>.
                    </p>
                    <p className="u-mb">
                      Gamemaster, conheça a sua missão em Logos!
                    </p>
                  </div>
                </div>
                <div className="o-grid__col u-10/12 u-5/12@sm u-mb" data-flout="col-- align-center-- justity-center--">
                  <img width="80%" src={logosBox} alt="spotgames"/>
                </div>
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <div className="o-grid u-mh-3x" data-flout="row-- align-center-- justity-around--">
                <div className="o-grid__col u-12/12 u-mb u-pl-0">
                  <h2 className="o-dashboard__title u-tw-600">
                    Missão:
                  </h2>
                  <p className="u-mb">
                    <b>Ajudar os seus Heróis e Heroínas a derrubar os muros de Logos para que os seus habitantes voltem a estar juntos!</b>
                  </p>
                  <p className="u-mb">
                    <b>Logos</b> é uma cidade murada, em que os seus cidadãos vivem isolados. A cada desafio de jogo corresponde uma oportunidade de derrubar muros e aproximar os cidadãos. E a única forma de o fazer é escrever.
                  </p>
                  <p className="u-mb">
                    Enquanto Gamemaster irá ajudar os seus heróis e heroínas a encontrar as suas verdadeiras motivações, a descobrir forças que não sabiam que tinham e a traçar, passo a passo, os seus caminhos na aventura da escrita pela Cidade de LOGOS.
                  </p>
                </div>
              </div>
            </div>
          </section>


          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <div className="u-ph-3x u-pv-2x o-dashboard__container-filled">
                <h2 className="o-dashboard__title u-mb-0 u-tw-600">
                  O que vos espera em Logos
                </h2>
                <p className="u-mb">
                  Em Logos vivem 690 cidadãos, distribuídos por 3 bairros:
                </p>
                <div className="o-grid u-mt-3x" data-flout="row--">
                  {neighborhoods.map((neighborhood, index) => (
                    <div className="o-grid__col u-12/12 u-6/12@sm u-4/12@mdl u-mb-6x u-mb-0@mdl" data-flout="col-- align-center--">
                      <div className="o-dashboard__badge">
                        <img src={neighborhood.img} alt={`Icon do ${neighborhood.name}`} />
                      </div>
                      <p className="u-text-c u-mt o-level-description">{neighborhood.text}</p>
                    </div>
                  ))}
                </div>
                <div className="o-grid u-mt-8x" data-flout="row-- align-center-- justity-around--">
                  <p className="o-grid__col u-12/12 u-mb">
                    Para conseguir juntar os cidadãos de cada Bairro os alunos terão de fazer 10 desafios, organizados em 3 níveis.
                  </p>
                  <div className="o-grid__col u-8/12 u-5/12@xxs u-6/12@sm u-mb-3x u-mt">
                    <img width="100%" src={mapLevels} alt="spotgames"/>
                  </div>
                  <div className="o-grid__col u-12/12 u-6/12@sm u-mb" data-flout="col-- align-start-- justity-center--">
                    {amulets.map((amulet, index) => (
                      <div className={`${index == amulets.length-1 ? "u-mb" : "u-mb-4x"} o-dashboard__amulet--${amulet.code}`} data-flout="nowrap-- align-center--">
                        <img src={amulet.img}
                          alt={`Icon do Amuleto ${amulet.name}`}
                          className={`u-mr o-dashboard__amulet-img`}
                          width={`48px`}
                        />
                        <p className={`o-dashboard__amulet-text`}>{amulet.text}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="o-grid u-mt-8x u-mb-4x" data-flout="row--">

                  <div className="o-grid__col u-6/12 u-mb u-mb-0@mdl" data-flout="col-- align-center--">
                    <img width="70%" src={dollCitizens} alt="1boneco-10cidadãos"/>
                  </div>

                  <div className="o-grid__col u-6/12 u-mb u-mb-0@mdl" data-flout="col-- align-center--">
                    <img width="75%" src={areaChallenge} alt="1área-1desafio"/>
                  </div>

                  <div className="o-grid__col u-6/12 u-mb u-mb-0@mdl" data-flout="col-- align-center--">
                    <p className="u-mt u-text-c u-ts-5">1 Boneco no Jogo = 10 Cidadãos</p>
                  </div>

                  <div className="o-grid__col u-6/12 u-mb u-mb-0@mdl" data-flout="col-- align-center--">
                    <p className="u-mt u-text-c u-ts-5">1 Área = 1 Desafio</p>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <p>
                No total, os alunos têm à sua disposição <b>30 desafios</b> que lhes permitirão transformar Logos numa cidade sem muros, onde todos os cidadãos estão em contacto, e ganhar os 4 amuletos disponíveis no jogo!
              </p>
              <p className="u-mt-3x u-mb">
                <b>O que mais treinam os seus Heróis e Heroínas em Logos?</b>
              </p>
              <div className="o-grid u-mt-2x u-mb-4x" data-flout="row-- justify-center--">
                {otherChallenges.map((c, index) =>
                  <div className={`o-grid__col u-5/12 u-2/12@sm u-mb u-mb-0@sm`} data-flout="col-- align-center--">
                    <div className={`o-dashboard__card`} data-flout="col-- align-center--">
                      <p className="o-dashboard__card-number u-mb">{c.quantity}</p>
                      <p className="o-dashboard__card-text">{c.text}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <Title modifiers="c-title--dashboard u-mb-6x" content="Tutoriais e Materiais de Apoio"/>

              <div className="o-grid" data-flout="row--">
                <div className="o-grid__col u-12/12" data-flout="align-start--">
                  <Link to={this.state.matrizPedagogicaPdf} target="_blank" className="u-mb-2x u-mb-0@sm u-mr-3x">
                    <Button>Matriz Pedagógica</Button>
                  </Link>
                  <Link to={this.state.desafiosSolucoesPdf} target="_blank" className="u-mb-2x u-mb-0@sm u-mr-3x">
                    <Button>Desafios & Soluções</Button>
                  </Link>
                  <Link to={availableSoonPdf} target="_blank" className="u-mb-2x u-mr-3x u-hidden">
                    <Button>O que os Gamemasters perguntam</Button>
                  </Link>
                </div>
                {/* <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video1}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 1/p>
                </div>
                <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video2}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 2</p>
                </div>
                <div className="o-grid__col u-12/12 u-4/12@mdl u-mb-4x">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video3}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 3 </p>
                </div>
                <div className="o-grid__col u-12/12 u-4/12@mdl">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video4}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 4 </p>
                </div>
                <div className="o-grid__col u-12/12 u-4/12@mdl">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video5}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 5 </p>
                </div>
                <div className="o-grid__col u-12/12 u-4/12@mdl">
                  <div className="c-video">
                    <iframe
                      className="c-video__iframe"
                      title="video-tutorial"
                      allowFullScreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      src={this.state.video6}
                    />
                  </div>
                  <p className="u-mt u-ts-4">Tutorial 6 </p>
                </div> */}
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <Title modifiers="c-title--dashboard u-mb-6x" content="Os Seus Heróis"/>

              <div className="o-grid" data-flout="row--">
                <div className="o-grid__col u-12/12 u-pl-0 u-mb-0" data-flout="col-reverse--row@sm--">
                  <div className="o-grid__col u-12/12 u-7/12@sm" data-flout="col--"  >
                    {((this.context.activeClass.status === CLASS_STATUS.PLAYING) || (this.context.activeClass.status === CLASS_STATUS.CLOSED && !this.state.closingClass)) &&
                      <div>
                        <p className="u-mb-2x">
                          {this.context.activeClass.status === CLASS_STATUS.PLAYING ?
                            "Link Inscrição Alunos"
                            :
                            "Terminou o seu jogo com esta turma. Os seus heróis já não poderão realizar novos desafios, mas podem continuar a consultar a sua Banda Desenhada, acedendo ao link:"
                          }
                        </p>
                        <form>
                          <textarea
                            className="u-sr-only"
                            ref={(registerurl) => this.registerURL= registerurl}
                            value={crewGameURL}
                            readOnly
                          />
                        </form>
                        <a className="o-dashboard__text-link" href={crewGameURL} target="_blank" rel="noreferrer">
                          {crewGameURL}
                        </a>
                       {
                       document.queryCommandSupported('copy') &&
                          <div data-flout="row--">
                            <Button onClick={this.copyToClipboard}>
                              copiar link
                            </Button>
                          </div>
                        }
                        <div className="u-color-logos-mustard u-ml-2x" data-flout="row--">{this.state.copySuccess}</div>
                      </div>
                    }
                  </div>

                  <div className="o-grid__col u-12/12 u-5/12@sm" data-flout="row-- justify-center--justify-end@sm-- align-start--" data-flitem="grow--">
                    {this.state.closingClass &&
                      <p className="c-loader__text">A terminar...</p>
                    }
                    {this.context.activeClass.status === CLASS_STATUS.PLAYING &&
                      <Button onClick={() => this.toggleModal(true)} >
                        Terminar Jogo
                      </Button>
                    }
                  </div>
                </div>
                <div className="o-grid__col u-12/12 u-mb-0">
                  <div className="o-table-wrapper">
                    <table className="o-table">
                      <thead className="o-table__head">
                        <tr className="o-table__headings">
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled o-table__cell--tr-str">Nº</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled u-text-l">NOME</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled">TUTORIAL</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled">NÍVEL 1</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled">NÍVEL 2</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled">NÍVEL 3</th>
                          <th className="o-table__field o-table__cell--dashboard o-table__cell--filled o-table__cell--tr-end">BAIRROS<br></br>SEM MUROS</th>
                          <th className="o-table__field o-table__cell--dashboard"></th>
                        </tr>
                      </thead>
                      <tbody className="o-table__body">
                        { this.context.activeClass.students.length ?
                          this.context.activeClass.students.sort((a, b) => a.studentNo - b.studentNo).map((student, key) =>
                            <tr key={key} className="o-table__row">
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled o-table__cell--tr-str">
                                <label className="o-table__cell__label">nº aluno:</label>
                                {student.studentNo}
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled u-text-l">
                                <div className="o-table__cell-truncate">
                                  <label className="o-table__cell__label">nome:</label>
                                  {this.shortenStudentName(student.name)}
                                </div>
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled">
                                <label className="o-table__cell__label">tutorial:</label>
                                {student.amulets.alpha ? "✓" : ""}
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled">
                                <label className="o-table__cell__label">nível 1:</label>
                                {student.challengesByLevel[1] }
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled">
                                <label className="o-table__cell__label">nível 2:</label>
                                {student.challengesByLevel[2] }
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled">
                                <label className="o-table__cell__label">nível 3:</label>
                                {student.challengesByLevel[3] }
                              </td>
                              <td className="o-table__cell  o-table__cell--dashboard o-table__cell--filled o-table__cell--tr-end">
                                <label className="o-table__cell__label">bairros sem muros:</label>
                                {student.solvedProblems}
                              </td>
                              <td className="o-table__cell o-table__cell--dashboard ">
                                <Link
                                  to={`/respostas/${student.studentId}`}
                                  target="_blank"
                                >
                                  <Button wrapperModifiers="u-mb-0">Respostas</Button>
                                </Link>
                              </td>
                            </tr>
                          )
                          :
                          (
                            <tr className="o-table__row">
                              <td className="o-table__cell  o-table__cell--dashboard u-pv-10x" colSpan={4}>
                              Ainda não há alunos registados.
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="o-dashboard__section">
            <div className="o-dashboard__container">
              <Title modifiers="c-title--dashboard u-mb-6x" content="Missões de Turma"/>

              <Missions
                classId={this.context.classId}
                gameStatus={this.context.activeClass.status}
                students={this.context.activeClass.students}
                missions={this.context.activeClass.missions}
              />
            </div>
          </section>
        </div>

        {this.context.activeClass.status === CLASS_STATUS.PLAYING &&
          <TerminateModal
            modalOpen={this.state.terminateModalOpen}
            toggleModal={this.toggleModal}
            title="Terminar Jogo"
            description="Atenção: esta ação é irreversível! Se terminar seu jogo com esta turma os seus heróis ficarão impedidos de realizar novos desafios. Caso queira apenas sair da sua página de professor, deverá clicar em 'sair' no topo da página"
            confirmQuestion= "Tem a certeza que quer terminar?"
            cancelButton="Não, voltar à página"
            confirmButton="Sim, terminar o jogo"
            confirmAction={this.terminateGame}
          />
        }
      </div>
    )
  }
}


export default TeacherDashboard
