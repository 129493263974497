import React from 'react';
import IconCheck from '../../assets/images/icon-check.svg'

class AvatarCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  themeClass(modifiers) {
    return `c-avatar-card ${modifiers ? modifiers : ""}`
  }

  render() {
    return (
      <div className={this.themeClass(this.props.modifiers)}>
        <div className="c-avatar-card__number">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 211.2 191.9"
            xmlSpace="preserve"
            className={`c-avatar-card__number-bg`}
          >
            <title>{"Cartão Aluno"}</title>
            <g id="avatar-card__Layer_2_1_">
              <g id="avatar-card__Layer_1-2">
                <circle className="avatar-card__st0" cx={105.6} cy={92.6} r={92.6} />
                <ellipse
                  className={`c-avatar-card__number-circle c-avatar-card__number-circle${this.props.verify ? "--verify" : ""}`}
                  cx={105.6}
                  cy={91.9}
                  rx={87.5}
                  ry={86.7}
                />
                <ellipse
                  className="avatar-card__st0"
                  cx={105.6}
                  cy={91.8}
                  rx={85.4}
                  ry={84.6}
                />
                <circle className={`c-avatar-card__number-circle c-avatar-card__number-circle${this.props.verify ? "--verify" : ""}`} cx={105.6} cy={90.8} r={78} />
                <circle className="avatar-card__st0" cx={105.6} cy={90.6} r={76.1} />
                <circle  className={`c-avatar-card__number-circle c-avatar-card__number-circle${this.props.verify ? "--verify" : ""}`} cx={105.6} cy={90.1} r={67.7} />
              </g>
            </g>
          </svg>
          <p className={`c-avatar-card__number-text`}>{this.props.number}</p>
        </div>
        <div className="c-avatar-card__name">
          <p className="c-avatar-card__name-text">{this.props.name}</p>
        </div>
        {this.props.extraInfo &&
          <p className="c-avatar-card__label">{this.props.extraInfo}</p>
        }
      </div>
    )
  }
}

export default AvatarCard
