import React from 'react';

import Backgrounds from './Backgrounds'
import CompleteAura from './CompleteAura'
import CompletePeople from './CompletePeople'
import Labels from './Labels'
import Neighborhood from './Neighborhood'
import NeighborhoodLabels from './NeighborhoodLabels'
import Walls from './Walls'

import BadgesBackgrounds from '../badges/Backgrounds'
import BadgesLocked from '../badges/Locked'
import BadgesAlpha from '../badges/Alpha'
import BadgesMeraki from '../badges/Meraki'
import BadgesRoseta from '../badges/Roseta'
import BadgesChimera from '../badges/Chimera'

class CityMain extends React.Component {
  render() {
    const student = this.props.student;
    const gameState = student.gameState;

    const scoreboard = gameState.scoreboard;
    // const scoreboard = {
    //   connectedCitizens: 0,
    //   destroyedWalls: 0,
    // }
    
    const amulets = gameState.amulets;
    // const amulets = {
    //   'alpha'   : false,
    //   'meraki'  : false,
    //   'rosetta' : false,
    //   'chimera' : false
    // }

    const walls = gameState.walls;
    //true when visible
    // const walls = {
    //   'afetos'    : [false, true, true],
    //   'descoberta' : [true, false, true],
    //   'causas'    : [true, true, true],
    //   'top'       : [true, false, true],
    //   'left'      : [true, true, true],
    //   'right'     : [true, true, true]
    // }

    const tutorialLevel = this.props.challenges["tutorial"];
    let tutorial = {};
    for (const [problemName, problem] of Object.entries(tutorialLevel)) {
      tutorial = problem.challenges[0];
    }

    // const afetosLevel = 1;
    // const aventuraLevel = 2;
    // const causasLevel = 3;

    const stats = {
      'afetos'    : gameState.levels.afetos,
      'descoberta' : gameState.levels.descoberta,
      'causas'    : gameState.levels.causas
    };

    const afetosChallenges = this.props.challenges.afetos;
    const aventuraChallenges = this.props.challenges.descoberta;
    const causasChallenges = this.props.challenges.causas;

    return (
      <svg viewBox="0 0 1024 1098" xmlns="http://www.w3.org/2000/svg">
        <CompleteAura
          gameComplete={scoreboard.connectedCitizens === 69}
        />
        <Labels />
        <Backgrounds
          stats={stats}
          gameComplete={scoreboard.connectedCitizens === 69}
        />
        <Walls
          stats={walls}
        />
        <Neighborhood
          category="descoberta"
          stats={stats["descoberta"]}
          challenges={aventuraChallenges}
          student={student}
        />
        <Neighborhood
          category="afetos"
          stats={stats["afetos"]}
          challenges={afetosChallenges}
          student={student}
        />
        <Neighborhood
          category="causas"
          stats={stats["causas"]}
          challenges={causasChallenges}
          student={student}
        />
        <CompletePeople
          stats={stats}
          gameComplete={scoreboard.connectedCitizens === 69}
        />
        <BadgesBackgrounds />
        {amulets['alpha'] ?
          <BadgesAlpha challenge={tutorial} />
          :
          <BadgesLocked challenge={tutorial} />
        }
        <BadgesMeraki
          unlocked={amulets['meraki']}
        />
        <BadgesRoseta
          unlocked={amulets['rosetta']}
        />
        <BadgesChimera
          unlocked={amulets['chimera']}
        />
        <NeighborhoodLabels/>
      </svg>
    )
  }
}

export default CityMain;
