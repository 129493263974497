import React from 'react';

function themeClass(modifiers){
  return `c-input-group ${modifiers ? modifiers : ""}`
}

function AppInput(props) {
  return (
    <div className={themeClass(props.modifiers)}>
      {props.label &&
        <label className="c-input-label" htmlFor={props.id}>{props.label}</label>
      }
      <div className="c-input-wrapper">
        <input
          className="c-input"
          type="text"
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          readOnly={props.readOnly}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
        ></input>
      </div>
    </div>
  )
}

export default AppInput
