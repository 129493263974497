import React from 'react';
import Input from '../components/game/appInput.js';
import Title from '../components/game/title.js';
import Button from '../components/game/appButton.js';
import ButtonIcon from '../components/game/appButtonIcon.js';
import logo from "../assets/images/logos/logos-icon.svg"
import AppFooter from '../components/game/appFooter.js';
import { teacherSignIn } from '../middlewares/firebase';

class TeacherLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubmit(event) {
    alert('An email will be sent to the following email: ' + this.state.email);

    teacherSignIn(this.state.email);

    event.preventDefault();
  }

  handleEnterKey = (event) => {
    // Login when Enter key press
    if(event.keyCode === 13) {
      this.handleSubmit(event)
    }
  }

  render() {
    return (
      <div className="o-app__bg-land">
        <div className="o-app__login">
          <div className="o-app__frame">
            <section className="o-view">
              <div data-flout="expand-- justify-center--">
                <div className="o-app__container" data-flout="justify-center-- expand--">
                  <img className="u-mb-5x u-mt-5x" width="50%" src={logo} alt="Login de Professor no Logos"/>
                  <Title
                    content="Login de Gamemaster"
                    modifiers="c-title--sm u-mb u-pl-2x u-pr-3x"
                  />
                  <Input
                    id="crew-name"
                    inverted={true}
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyDown={this.handleEnterKey}
                    readOnly={false}
                    placeholder="Escreva aqui o seu email"
                  />
                </div>
                <div className="o-app__container c-input-group u-mv-2x" data-flout="justify-center-- expand--">
                  <ButtonIcon onClick={this.handleSubmit}>
                    Entrar
                  </ButtonIcon>
                </div>
              </div>
              <AppFooter isTeacher/>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherLogin
