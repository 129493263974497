export const structureGameChallenges = (challengeDocs) => {
  let levels = {
      tutorial: { },
      afetos: { },
      descoberta: { },
      causas: { }
  };

  challengeDocs.forEach((challengeDoc) => {
    let challenge = challengeDoc.data();
    let challengeId = challengeDoc.id;

    let level = challenge.level;
    
    // Lel
    if(level === "afectos"){
      challenge.level = "afetos";
      level = "afetos";
    }

    let problem = challenge.problem;

    if(!levels[level][problem]) {
      levels[level][problem] = [];
    }

    challenge.id = challengeId;

    levels[level][problem][challenge.order] = challenge;
  });

  return levels;
}

export const buildInitialGameState = (structuredChallenges) => {

  let initialGameState = {
    scoreboard: {
      connectedCitizens: 0,
      destroyedWalls: 0
    },
    walls: {
      afetos:     [true, true, true],
      descoberta: [true, true, true],
      causas:     [true, true, true],
      top:        [true, true, true],
      left:       [true, true, true],
      right:      [true, true, true]
    },
    amulets: {
      alpha   : false,
      meraki  : false,
      rosetta : false,
      chimera : false
    },
    levels: {}
  };

  Object.keys(structuredChallenges).forEach((level, idx) => {
    let levelObject = {
      order: level,
      unlocked: (idx === 0 ? true : false),
      completed: false,
      problems: []
    };

    Object.keys(structuredChallenges[level]).forEach((problem, idx) => {
      let problemObject = {
        name: problem,
        nChallengesCompleted: 0,
        unlocked: (level === 'tutorial' ? true : false),
        completed: false,
        challenges: []
      };

      structuredChallenges[level][problem].forEach((challenge, idx) => {
        problemObject.challenges.push({
          id: challenge.id,
          type: challenge.type,
          completed: false,
          hidden: challenge.startsHidden
        });
      });

      levelObject.problems.push(problemObject);
    });

    initialGameState.levels[level] = levelObject;
  });


  return initialGameState;
}