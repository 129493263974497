import React, { useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { CLASS_STATUS } from '../utils/constants'
import AvatarSelection from '../views/avatarSelection.js'
import Level from '../views/level.js'
import ChallengesView from '../views/challenges.js'
import Logout from '../views/logout'

import { StudentDataContext } from "../providers/StudentDataProvider"

import Loader from '../components/game/loader'

const StudentRouter = (props) => {
  const { student, loading, activeClass }  = useContext(StudentDataContext);

  if( loading ) {
    // In this particular case, we're still loading student data
    return <Loader/>
  }

  if( !student ) {
    // In this case we have logged in, but have no student object
    // meaning we have to complete student registration
    return <AvatarSelection />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/nivel' />
        </Route>
        <Route path='/nivel'
          component={Level}
        />
        <Route path='/desafio'
          component={ChallengesView}
        />
        <Route path='/logout'>
          <Logout />
        </Route>
        <Route>
          <Redirect to='/nivel' />
        </Route>
      </Switch>
    </BrowserRouter>
  )
};

export default StudentRouter;
