import * as React from "react"
import AppButton from '../game/appButton'

function themeClass(modifiers) {
  return `c-button ${modifiers ? modifiers : ""}`
}

class Button extends React.Component {
  render() {
    return (
      <AppButton
        className={themeClass(this.props.modifiers)}
        disabled={this.props.disabled}
        name={this.props.name}
        onClick={this.props.onClick}
      >
        {this.props.name}
      </AppButton>
    )
  }
}

export default Button
