import * as React from "react"
import Button from "../game/appButton.js"
import ButtonIcon from "../game/appButtonIcon.js"


class ConfirmationModal extends React.Component {

  render() {
    return(
      <React.Fragment>
        <div className="o-modal-overlay-wrapper" open={this.props.modalOpen}>
          <div className="o-modal o-modal--small" >
            <ButtonIcon
              className="o-modal__close"
              onClick={() => this.props.toggleModal(false)}>
              X
            </ButtonIcon>
            <div className="o-modal__header">
              <h1 className="">
                {this.props.title}
              </h1>
            </div>
            <div className="o-modal__body u-text-c">
              <p>{this.props.description}</p>
              <br/>
              <p className="u-tw-600">{this.props.confirmQuestion}</p>
            </div>
            <div className="o-modal__footer">
              <div className="o-modal__actions o-grid" data-flout="col-- row@sm--">
                <div className="o-grid__col u-12/12 u-6/12@sm">
                  <Button onClick={() => this.props.toggleModal(false)} >
                    {this.props.cancelButton}
                  </Button>
                </div>
                <div className="o-grid__col u-12/12 u-6/12@sm">
                  <Button onClick={this.props.confirmAction} >
                    {this.props.confirmButton}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ConfirmationModal
