import * as React from "react"

import logos from "../../assets/images/logos/logos.svg"
import cityTile from "../../assets/images/city-tile.svg"

function themeClass(modifiers) {
  return `c-tile-logos ${modifiers ? modifiers : ""}`
}

function LogosTile(props) {
  return (
    <div className={themeClass(props.modifiers)}>
      <img src={logos} alt="Logos logo"
      className="c-tile-logos__logo"
      />
      <img src={cityTile} alt="Logos city tile"
        className="c-tile-logos__tile"
      />
    </div>
  )
}

export default LogosTile
