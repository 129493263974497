import * as React from "react"
import ReactMarkdown from "react-markdown"

class Input extends React.Component {
  render(){
    let inputField
    if (this.props.type === "long") {
      inputField = (
        <textarea rows="8" className="o-challenge__input o-challenge__input--textarea" onChange={this.props.onChange} value={this.props.value} disabled={this.props.challengeCompleted}/>
      )
    } else if (this.props.type === "file") {
      if (this.props.challengeCompleted) {
        inputField = (
          <a
            className="o-dashboard__text-link"
            href={this.props.value} target="_blank" rel="noreferrer">
            descarregar ficheiro
          </a>
        )
      } else {
        inputField = (
          <React.Fragment>
          <input type="file" id="files" className="o-challenge__upload-og" onChange={this.props.onChange} />
          <label htmlFor="files" data-flout="row-- justify-between-- align-center-- nowrap--">
          <div className="c-button-wrapper u-mb-0 u-7/12">
            <div className="c-button">Carrega um ficheiro</div>
          </div>
          <div className="o-challenge__upload-info">{this.props.value || 'Nenhum ficheiro selecionado'}</div>
          </label>
          </React.Fragment>
        )
      }
    } else {
      inputField = (
        <input type={this.props.type} className="o-challenge__input" onChange={this.props.onChange} value={this.props.value} disabled={this.props.challengeCompleted}/>
      )
    }
    return (
      <div className="o-challenge__field">
        <ReactMarkdown className="o-challenge__label" linkTarget="_blank" children={this.props.label} />
        {inputField}
      </div>
    )
  }
}

export default Input
