import React from 'react';

const completeBgShapes = {
  "descoberta": "M512 429.851V80.93c.593.025 1.187.038 1.783.038 22.344 0 40.485-18.14 40.485-40.484 0-14.677-7.828-27.541-19.531-34.639 3.284 1.96 6.268 4.372 8.867 7.149 244.029 17.29 436.924 221.017 436.924 469.441 0 97.173-29.513 187.506-80.076 262.544.136 1.348.205 2.715.205 4.098 0 .536-.01 1.07-.031 1.602.015-.442.022-.885.022-1.329 0-22.209-18.031-40.24-40.241-40.24-10.454 0-19.982 3.994-27.141 10.541L557.967 508.28c3.802-7.378 5.949-15.746 5.949-24.612 0-29.074-23.089-52.797-51.916-53.817z",
  "afetos": "M557.958 508.298l275.781 210.927c-8.323 7.373-13.573 18.141-13.573 30.125 0 22.21 18.032 40.241 40.241 40.241 2.177 0 4.313-.173 6.397-.507-86.339 100.378-214.255 163.985-356.909 163.985-149.809 0-283.364-70.147-369.612-179.397 5.024 2.268 10.595 3.531 16.46 3.531 22.113 0 40.066-17.953 40.066-40.066 0-10.161-3.791-19.443-10.033-26.508l275.872-201.43c9.105 16.858 26.933 28.32 47.417 28.32 20.85 0 38.948-11.874 47.893-29.221z",
  "causas": "M462.62 509.148L186.813 710.671c-7.346-8.338-18.098-13.6-30.07-13.6-22.113 0-40.066 17.953-40.066 40.066 0 1.411.073 2.805.216 4.178-49.061-74.285-77.632-163.272-77.632-258.88 0-251.234 197.284-456.753 445.303-469.961-6.977 7.277-11.265 17.147-11.265 28.01 0 21.747 17.185 39.513 38.701 40.446v348.921c-.642-.023-1.287-.034-1.935-.034-29.721 0-53.851 24.129-53.851 53.851 0 9.213 2.319 17.89 6.406 25.48z"
}

class CityBackgrounds extends React.Component {

  completeBackgrounds(neighborhood, complete, gameComplete){
    // render a dark background if the neighborhood is complete
    if(complete || gameComplete){
      return(
        <path d={completeBgShapes[neighborhood]} fill="rgb(79,67,59)" transform="translate(1.26046 33.0315) scale(1.00225)" id="Descoberta"/>
      )
    }
  }

  render() {
    const aventuraComplete = (this.props.stats["descoberta"].completed);
    const afetosComplete = (this.props.stats["afetos"].completed);
    const causasComplete = (this.props.stats["causas"].completed);
    const gameComplete = this.props.gameComplete;

    // Backgrounds for the city and each neighborhood
    return (
      <g id="Backgrounds" transform="translate(-1.25764 -32.9576) scale(.99776)">
        <path d="M484.899 12.456C492.272 4.86 502.586.138 513.996.138c11.675 0 22.204 4.945 29.608 12.856 244.029 17.29 436.924 221.017 436.924 469.441 0 97.173-29.513 187.506-80.076 262.544.136 1.348.205 2.715.205 4.098 0 20.094-14.648 36.795-33.843 39.996-86.349 100.389-214.265 163.996-356.919 163.996-150.11 0-283.899-70.429-370.118-180.04-11.698-5.822-20.194-17.13-22.111-30.547-49.535-74.522-78.405-163.939-78.405-260.047 0-251.359 197.481-456.959 445.638-469.979z" fill="rgb(185,173,160)" transform="translate(1.26046 33.0315) scale(1.00225)" id="Main"/>
        {this.completeBackgrounds("descoberta",aventuraComplete, gameComplete)}
        {this.completeBackgrounds("afetos",afetosComplete, gameComplete)}
        {this.completeBackgrounds("causas",causasComplete, gameComplete)}
        <g id="Decorations">
          <path d="M858.375 789.589C773.519 885.189 649.743 945.466 512 945.466c-143.621 0-272.057-65.531-357.025-168.31.449.031.901.055 1.354.071C241.122 879.381 369.02 944.468 512 944.468c137.166 0 260.452-59.901 345.105-154.952.422.031.845.055 1.27.073zm-11.223-2.081C764.239 878.544 644.745 935.728 512 935.728c-138.149 0-261.946-61.935-345.094-159.502.364-.083.726-.172 1.085-.265C250.988 873.111 374.355 934.73 512 934.73c132.303 0 251.414-56.929 334.13-147.582.339.124.68.245 1.022.36zm51.246-51.801c47.811-72.751 75.635-159.788 75.635-253.272 0-242.227-186.805-441.168-424.078-460.492-.184-.353-.374-.702-.567-1.047C787.467 39.937 975.03 239.455 975.03 482.435c0 93.967-28.051 181.434-76.232 254.479-.127-.405-.26-.807-.4-1.207zm-5.138-9.973c44.958-70.258 71.035-153.757 71.035-243.299 0-235.599-180.527-429.366-410.677-450.401-.075-.343-.154-.684-.237-1.023 230.833 20.946 411.911 215.205 411.911 451.424 0 89.89-26.221 173.703-71.419 244.192-.201-.301-.405-.599-.613-.893zm-774.713.792C74.444 655.624 48.97 571.981 48.97 482.435c0-244.385 189.739-444.805 429.812-461.854-.197.344-.389.691-.577 1.042C238.942 38.965 49.967 238.806 49.967 482.435c0 89.022 25.231 172.197 68.942 242.783-.128.433-.249.869-.362 1.308zm4.683-11.005c-40.959-68.124-64.522-147.875-64.522-233.086 0-237.62 183.232-432.781 415.99-451.776-.086.339-.168.679-.246 1.021C242.381 50.771 59.705 245.438 59.705 482.435c0 84.849 23.415 164.272 64.123 232.143-.204.312-.403.626-.598.943z" fill="rgb(241,227,205)" transform="translate(1.26046 33.0315) scale(1.00225)"/>
          <path d="M881.222 751.877c11.144 6.276 18.678 18.221 18.678 31.913 0 20.2-16.4 36.6-36.6 36.6-11.241 0-21.306-5.079-28.022-13.065.168-.193.383-.469.63-.806 6.531 7.861 16.381 12.871 27.392 12.871 19.648 0 35.6-15.952 35.6-35.6 0-13.292-7.3-24.892-18.107-31.007.165-.298.313-.601.429-.906zm-2.616 4.484c9.605 5.376 16.103 15.65 16.103 27.429 0 17.335-14.074 31.409-31.409 31.409-10.15 0-19.182-4.825-24.926-12.305l.182-.278c.081-.126.163-.252.245-.378l.07-.109c5.577 7.414 14.447 12.212 24.429 12.212 16.861 0 30.551-13.69 30.551-30.551 0-11.476-6.341-21.482-15.708-26.704.169-.23.32-.474.463-.725zM140.681 740.226c-.027.087-.048.173-.062.256-.052.309-.048.617-.002.924-10.784 6.119-18.067 17.708-18.067 30.984 0 19.648 15.952 35.6 35.6 35.6 10.814 0 20.509-4.832 27.042-12.456.204.3.402.585.589.848-6.713 7.723-16.606 12.608-27.631 12.608-20.2 0-36.6-16.4-36.6-36.6 0-13.875 7.738-25.958 19.131-32.164zm1.378 4.19c.161.243.322.481.475.716-8.941 5.332-14.935 15.101-14.935 26.258 0 16.861 13.69 30.551 30.551 30.551 9.611 0 18.191-4.448 23.793-11.395.143.229.305.487.483.767-5.762 7.011-14.501 11.486-24.276 11.486-17.335 0-31.409-14.074-31.409-31.409 0-11.455 6.145-21.486 15.318-26.974zM479.853 72.021c.935-19.371 16.957-34.811 36.557-34.811 20.2 0 36.6 16.4 36.6 36.6 0 1.82-.133 3.609-.39 5.36-.331-.047-.659-.103-.987-.167.248-1.694.377-3.429.377-5.193 0-19.648-15.952-35.6-35.6-35.6-18.882 0-34.35 14.732-35.528 33.32-.344.092-.668.233-.962.44-.022.016-.045.033-.067.051zm5.219-.34c1.097-16.345 14.719-29.28 31.338-29.28 17.335 0 31.409 14.074 31.409 31.409 0 1.366-.087 2.712-.257 4.032-.277-.086-.556-.169-.834-.247.154-1.24.233-2.504.233-3.785 0-16.861-13.69-30.551-30.551-30.551-16.172 0-29.426 12.593-30.483 28.497-.272-.011-.559-.039-.855-.075z" fill="rgb(241,227,205)"/>
        </g>
      </g>
    )
  }
}

export default CityBackgrounds;
