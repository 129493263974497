import React from 'react';

import Title from '../components/game/title.js';
import Input from '../components/game/appInput.js';
import AppButtonIcon from '../components/game/appButtonIcon.js';
import AvatarFull from '../components/avatar/full.js';
import ButtonIcon from '../components/game/appButtonIcon.js';
import logo from "../assets/images/logos/logos-icon.svg"

import { StudentDataContext } from '../providers/StudentDataProvider.js';
import { auth, createStudent } from '../middlewares/firebase';
import { parseGameVersion } from "../utils/funcs"

class AvatarSelection extends React.Component {
  static contextType = StudentDataContext;

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      confirmDisabled: true,
      avatarPrev: 6,
      avatarTheme: 1,
      avatarNext: 2,
      loading: false
    }
  }

  changeTheme(decrease) {
    var currTheme = [this.state.avatarPrev, this.state.avatarTheme, this.state.avatarNext]

    // Increase or decrease theme parameter
    const newTheme = currTheme.map(function(value) {
      if(decrease) value --
      else value ++

      // Loop parameters between 1 and 6
      if(value > 6) value = 1
      else if(value < 1) value = 6

      return value
    })

    this.setState({
      avatarPrev: newTheme[0],
      avatarTheme: newTheme[1],
      avatarNext: newTheme[2]
    })
  }

  updateName(event){
    // Update avatar name state variable
    this.setState({ name: event.target.value})

    // Unlock confirm button if name input filled
    if(event.target.value.length > 0) {
      this.setState({ confirmDisabled: false})
    } else {
      this.setState({ confirmDisabled: true})
    }
  }

  registerStudent() {
    this.setState({
      loading: true
    });

    createStudent(auth.currentUser.uid, {
      studentNo: this.context.claims.studentNo,
      classId: this.context.claims.classId,
      name: this.state.name,
      avatar: this.state.avatarTheme,
      version: this.context.activeClass.version
    }, this.context.challenges );
  }

  render() {
    return (
      <div className="o-app__bg-land">
        <div className="o-app__login">
          <div className="o-app__frame">
            <section className="o-view">
              <div data-flout="expand-- justify-center--">
                <div className="o-app__container o-app__container--sm" data-flout="justify-center-- expand--">
                  <img className="u-mb-5x u-mt-5x" width="60%" src={logo} alt="Login de Professor no Logos"/>
                  <Title
                    content="A tua aventura em Logos vai começar"
                    modifiers="c-title--sm u-mb-2x u-pl-2x u-pr-3x"
                  />
                  <Input
                    id="crew-name"
                    inverted={true}
                    label="Como te vais chamar?"
                    placeholder="Nome escolhido"
                    modifiers="u-mb-2x"
                    onChange={(e) => this.updateName(e)}
                  />
                </div>
                <div className="o-app__container c-input-group u-mv-2x" data-flout="justify-center-- expand--">
                  <ButtonIcon
                    disabled={this.state.confirmDisabled}
                    onClick={() => this.registerStudent()}
                  >
                    Entrar
                  </ButtonIcon>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default AvatarSelection
