import React from "react"
import { Link } from "react-router-dom"
import AvatarCard from '../../components/avatar/card.js'
import Button from '../../components/game/appButton.js'
import { MISSION_DESCRIPTIONS } from '../../utils/copy.js';
import { CLASS_STATUS } from "../../utils/constants"
import { parseGameVersion } from "../../utils/funcs.js"
import { completeMission } from "../../middlewares/firebase";

class Missions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMission: 0,
      activeMission: 0,
      allMissionsCompleted: false
    }
  }

  componentDidMount() {
    this.getActiveMission(this.props.missions, true)
  }

  componentDidUpdate(nextProps) {
    if(nextProps.missions !== this.props.missions) {
      this.getActiveMission(this.props.missions, false)
    }
  }

  setSelectedMission(index) {
    this.setState({selectedMission: index})
  }

  getMissionPercentage(value) {
    return parseInt(value * 100)
  }

  getActiveMission(missions, updateSelected) {
    // Find the index of the first mission to complete
    if(missions){
      let newActiveMission = missions.findIndex(mission => mission.completed === false);
      this.setState({activeMission: newActiveMission})
      // Define allMissionsCompleted as true if can't find a mission to be completed
      if(newActiveMission === -1) {
        this.setState({allMissionsCompleted: true})
      }
      // Update the selectedMission in order to reveal it on the missions table
      if(updateSelected) {
        this.setState({selectedMission: newActiveMission})
      }
    }
  }

  renderAvatars(students, misisonIndex) {
    // Render the avatar defined by each student
    var avatarsEl = students.sort((a, b) => a.studentNo - b.studentNo).map(function(student, key) {
      return (
        <AvatarCard
          key={key}
          modifiers="c-avatar-card--sm"
          game={parseGameVersion(student.version)}
          name={student.name}
          number={student.studentNo}
          verify={student.missions[misisonIndex]}
          extraInfo={`${student.solvedChallenges} Desafios`}
        />
      )
    }, this)
    return avatarsEl
  }

  renderCompleteButton(index, status) {
    // Render the complete button on the currently active mission
    // but only if the game status is "playing" and the mission is manually completable
    if((index === 0 || index === 4 || index === 9 || index === 14)
      && (parseInt(index) === this.state.activeMission)
      && status === CLASS_STATUS.PLAYING
    ) {
      return(
        <Button
          wrapperModifiers="u-mb-0"
          onClick={() => completeMission(this.props.classId, index)}
        >
          Concluir Missão
        </Button>
      )
    }
  }

  renderShowButton(index, status) {
    if(this.state.activeMission === parseInt(index)){
      return(
        <Link to='/missoes' target="_blank">
          <Button>
            {status === CLASS_STATUS.OPEN ? "Começar a jogar" : "Mostrar missão"}
          </Button>
        </Link>
      )
    }
  }

  renderProgressBar(missions, index) {
    let completed = false;
    let progress = 0;
    if(missions){
      completed = missions[index].completed;
      progress = missions[index].progress;
    }
    return(
      <div data-flout="row--">
        <progress
          className={`c-progress ${completed && "c-progress--completed"}`}
          max="100"
          value={this.getMissionPercentage(progress)}
        >
          {this.getMissionPercentage(progress)}%
        </progress>
        <span className="u-ml u-tw-600">{this.getMissionPercentage(progress)}%</span>
      </div>
    )
  }

  renderMissionContent(index, students, missions) {
    // Render the mission content accordingly if is manually completable or not
    if(!missions) {
      return(
        <div>
          <p>Comece a jogar para ter acesso às próximas missões.</p>
        </div>
      )
    }
    if(index === 0 || index === 4 || index === 9 || index === 14) {
      return(
        <div>
          <p className="u-tw-600 u-mb">
            {missions[index].completed ? "Missão concluída" : "Missão por concluir"}
          </p>
          {!missions[index].completed &&
            <p>Esta missão deverá ser concluida manualmente.</p>
          }
        </div>
      )
    } else {
      return (
        <div data-flout="justify-center--">
          {this.renderAvatars(students, index)}
        </div>
      )
    }
  }


  render() {
    return(
      <div className="o-grid" data-flout="row--">
        <div className="o-grid__col u-12/12">
          <div className="o-table-wrapper">
            <table className="o-table o-table--missions">
              <thead className="o-table__head">
                <tr className="o-table__headings o-table__headings--missions">
                  <th className="o-table__field o-table__cell--filled o-table__cell--tr-str u-text-l">MISSÃO</th>
                  <th className="o-table__field o-table__cell--filled u-text-l">STATUS</th>
                  <th className="o-table__field o-table__cell--filled o-table__cell--tr-end u-text-r">DETALHES</th>
                </tr>
              </thead>
              <tbody className="o-table__body">
                {Object.keys(MISSION_DESCRIPTIONS).map((key, index) =>
                  <React.Fragment key={index}>
                    <tr className={`${(this.state.activeMission < index && !this.state.allMissionsCompleted) && "u-hidden"}`}>
                      <th className="u-pt-2x"></th>
                    </tr>
                    <tr className={`o-table__row o-table__row--collapsable-head
                      ${this.state.selectedMission === index ? "uncollapsed" : "o-table__row--missions" }
                      ${(this.state.activeMission < index && !this.state.allMissionsCompleted) && "u-hidden"}`
                    }>
                      <td className={`o-table__cell o-table__cell--filled${this.state.activeMission === parseInt(index) ? "-active" : ""} o-table__cell--tr-str u-text-l`}>
                        <div data-flout="row-- align-center--">
                          <div>
                            <p>{key} - {MISSION_DESCRIPTIONS[key]}</p>
                          </div>
                        </div>
                      </td>
                      <td className={`o-table__cell o-table__cell--grow-xs o-table__cell--filled${this.state.activeMission === parseInt(index) ? "-active" : ""} u-text-l`}>
                        <div data-flout="align-center-- nowrap--">
                          {this.renderProgressBar(this.props.missions, index)}
                        </div>
                      </td>
                      <td className={`o-table__cell o-table__cell--trigger  o-table__cell--filled${this.state.activeMission === parseInt(index) ? "-active" : ""} o-table__cell--tr-end u-text-r`}>
                        <label
                        className={`${this.state.selectedMission !== index ? "o-table__trigger" : "o-table__trigger o-table__trigger--open"}`}
                        onClick={
                          this.state.selectedMission !== index ?
                          () => this.setSelectedMission(index) :
                          () => this.setSelectedMission(null)
                        }>
                        {this.state.selectedMission !== index ?
                          "ver detalhes" : "esconder detalhes"
                        }
                        </label>
                      </td>
                    </tr>
                    <tr className={`o-table__row o-table__row--collapsable
                      ${this.state.selectedMission === index && "uncollapsed"}
                      ${(this.state.activeMission < index && !this.state.allMissionsCompleted) && "u-hidden"}`
                    }>
                      <td className="o-table__cell o-table__cell--collapsable u-text-l" colSpan="3">
                        <div className="o-table__collapse-content">

                          <div className="o-grid u-ph-0 u-pv u-pv-2x@sm u-ph-2x@sm">
                            <div className="o-grid__col u-12/12 u-9/12@sm u-mb" >
                            {this.renderMissionContent(index, this.props.students, this.props.missions)}
                            </div>
                            <div className="o-grid__col u-12/12 u-3/12@sm u-mb" data-flout="col-- align-bottom--">
                              {this.renderShowButton(index, this.props.gameStatus)}
                              {this.renderCompleteButton(index, this.props.gameStatus)}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}


export default Missions
