import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { STUDENT_EMAIL_DOMAIN, CLASS_STATUS } from "../utils/constants";
import { buildInitialGameState, structureGameChallenges } from "./helpers/gameHelpers";

const firebaseConfig = {
  apiKey: "AIzaSyCV0Nz5G0YABmevfkWGlbEP3vaAdK_PxNo",
  authDomain: "logos-production.firebaseapp.com",
  projectId: "logos-production",
  storageBucket: "logos-production.appspot.com",
  messagingSenderId: "648283373888",
  appId: "1:648283373888:web:028c233fe6b8520e9bfaa5"
};

firebase.initializeApp(firebaseConfig);

export const fire = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions('europe-west1');
export const storage = firebase.storage();

if (process.env.NODE_ENV === 'development') {
  auth.useEmulator('http://localhost:9099/');
  firestore.useEmulator('localhost', 8080);
  functions.useEmulator('localhost', 5001);
}

const teacherSignIn = (email) => {
  let teacherSignInFunction = functions.httpsCallable('sendSignInEmail');

  return teacherSignInFunction({
    email: email,
    url: window.location.origin
  })
    .then((result) => {
      window.localStorage.setItem('emailForSignIn', email);

      if (result.data.error) {
        console.error(result.data.error);
      }

      if (result.data.redirect) {
        window.location.href = result.data.redirect;
      }

      return result.data;
    })
}

const studentSignIn = (classId, studentNo) => {
  const studentEmail = `${classId}-${studentNo}@${STUDENT_EMAIL_DOMAIN}`;

  let getStudentTokenFunction = functions.httpsCallable('getTokenForStudent');
  getStudentTokenFunction({
    email: studentEmail,
    classId,
    studentNo,
  })
    .then((result) => {
      if (result.data.error) {
        console.error(result.data.error);
      }

      auth.signInWithCustomToken(result.data.token);
    });
}

const isCurrentTeacherAdmin = () => {
  return ( auth.currentUser.email.endsWith("@spotgames.org") || auth.currentUser.email.endsWith("@resdochao.org") );
}

const getClassesForCurrentTeacher = async() => {
  let teacherId = auth.currentUser.uid;
  let admin = isCurrentTeacherAdmin();
  let teacherClasses;

  if( admin ) {
    teacherClasses = (await firestore.collection("classes")
      .get()).docs;

  } else {
    let classIds = (await firestore.collection("teacherClasses")
      .where("teacherId", "==", teacherId)
      .get()).docs.map(doc => doc.data().classId);
    
    // we need to chunk the requests since Firebase only allows for 10 to be queried at a time
    // TODO: This should be pushed somehwhere else, it's nasty logic here.
    let classQueries= [], CHUNK=10;
    for(let i=0, j=classIds.length; i<j; i+= CHUNK) { //We're querying in chunks of 10
      let subQueryIds = classIds.slice(i, i+CHUNK);

      classQueries.push(
        firestore.collection("classes")
          .where(fire.firestore.FieldPath.documentId(), "in", subQueryIds)
          .get()
      );
    }

    // Flatten all the results
    teacherClasses = [].concat.apply([],
      (await Promise.all(classQueries)).map(res => res.docs)
    );
  }

  return teacherClasses;
}


const activateClass = async (classId) => {
  return firestore
    .collection("classes")
    .doc(classId)
    .update({
      status: CLASS_STATUS.PLAYING
    });
}

const closeClass = async (classId) => {
  return firestore
    .collection("classes")
    .doc(classId)
    .update({
      status: CLASS_STATUS.CLOSED
    });
}

const initMissions = async(classId, missionIdx = -1) => {
  let initFunction = functions.httpsCallable('initializeMissions');

  return initFunction({ classId, missionIdx });
}

const fetchActiveClass = async(classId) => {
  let activeClassPromise = firestore.collection('classes')
    .doc(classId)
    .get();
  
  return activeClassPromise.then((res) => {
    return res.data();
  })
}

const completeMission = async (classId, missionIndex) => {
  let activeClass = await fetchActiveClass(classId);

  if (!activeClass.missions) {
    return initMissions(classId, missionIndex);
  } else {
    let missions = activeClass.missions;
    missions[missionIndex].completed = true;
    missions[missionIndex].progress = 1.0;

    return firestore.collection('classes')
      .doc(classId)
      .update({ missions });
  }
}

const getAvailableChallenges = async (version) => {
  let challengeDocs = await firestore.collection("challenges")
    .where("version", "==", version)
    .get();

  return structureGameChallenges(challengeDocs);
}

const createStudent = async (studentId, studentParams, structuredChallenges) => {
  studentParams.gameState = buildInitialGameState(structuredChallenges);

  let classRef = firestore.collection('classes').doc(studentParams.classId);
  await classRef.update({
    students: firebase.firestore.FieldValue.arrayUnion({
      studentId,
      studentNo: studentParams.studentNo,
      name: studentParams.name,
      amulets: {},
      version: studentParams.version,
      missions: Array(20).fill(false),
      solvedProblems: 0,
      solvedChallenges: 0,
      challengesByLevel: {
        1: 0,
        2: 0,
        3: 0
      }
    })
  });

  return firestore
    .collection('students')
    .doc(studentId)
    .set(studentParams);
}

const submitAnswer = async (studentId, level, problem, challenge, answers) => {
  let structuredAnswers = await parseAnswerObject(studentId, answers);

  let answerObject = {
    level,
    problem,
    title: challenge.title,
    type: challenge.type,
    order: challenge.order,
    answers: structuredAnswers
  };


  return firestore
    .collection(`students/${studentId}/answers`)
    .add(answerObject);
}

const parseAnswerObject = async (studentId, answers) => {
  let structuredAnswers = [];

  let keys = Object.keys(answers);

  for (let i = 0; i < keys.length; i++) {
    let order = keys[i];
    let answer = {
      order: order,
      type: answers[order].type,
      label: answers[order].label
    };

    if (answers[order].type === "file") {
      answer.value = (await uploadFile(studentId, answers[order].file));
    } else {
      answer.value = answers[order].value;
    }

    structuredAnswers.push(answer);
  }

  structuredAnswers = structuredAnswers.sort(compareSteps);

  return structuredAnswers;
}

const uploadFile = async (studentId, file) => {
  if (process.env.NODE_ENV === 'development') {
    return file.name;
  }

  let claims = (await auth.currentUser.getIdTokenResult()).claims;

  let storageRef = storage.ref();
  let fileRef = storageRef.child(`${studentId}/${Date.now()}-${file.name}`);
  let metadata = {
    customMetadata: {
      "class": claims.classId
    }
  };

  let snapshot = await fileRef.put(file, metadata);

  return (await snapshot.ref.getDownloadURL());
}

const compareSteps = (a, b) => {
  if (a.order > b.order)
    return 1;
  if (a.order < b.order)
    return -1;
  return 0;
}

const getStepsForChallenge = async (challengeId) => {
  let stepDocs = (await firestore.collection(`challenges/${challengeId}/steps`)
    .get()).docs;

  return stepDocs.map(doc => doc.data()).sort(compareSteps);
}

export {
  teacherSignIn,
  studentSignIn,
  isCurrentTeacherAdmin,
  getClassesForCurrentTeacher,
  activateClass,
  closeClass,
  completeMission,
  getAvailableChallenges,
  createStudent,
  submitAnswer,
  getStepsForChallenge
};
