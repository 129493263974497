import * as React from "react"

import Background from '../../assets/images/background-button.svg'

class AppButtonIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconUrl: ""
    }
  }

  /* PROPS

    modifiers: [CSS classes]
    icon: [icon image to display]
    name: [text name for accessibility purposes]
    verifyIcon: [verify icon on the top-right corner]
    disabled: [disable the button as native HTML]
    onClick: [function to trigger on button click]

  ---- */

  componentDidMount() {
    // Render icon described as prop
    // Icons available: accao, caixa-ferramentas, descoberta, dia-diferente, influencer
    //                  left, logout, mobilizacao, teu-programa, tempo-antena, right
    if (this.props.icon) {
      import(`../../assets/images/icon-${this.props.icon}.svg`).then(image => {this.setState({iconUrl: image.default})})
    }
  }

  themeClass(modifiers) {
    return `c-button c-button--icon ${modifiers ? modifiers : ""}`
  }

  render() {
    return (
      <button
        className={this.themeClass(this.props.modifiers)}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        <img className="c-button__background" src={Background} alt=""/>
        {this.props.icon ?
          <img className="c-button__img" src={this.state.iconUrl} alt=""/>
          :
          <span className="c-button__content" >
            {this.props.children}
          </span>
        }
      </button>
    )
  }
}

export default AppButtonIcon
