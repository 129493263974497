import * as React from "react"
import { auth } from "../../middlewares/firebase";
import Button from './appButtonIcon.js'

function AppButtonLogout(props) {
  return (
    <Button
      modifiers="c-button--sm "
      onClick={(e) => auth.signOut()}
    >
      <span>{props.children}</span>
    </Button>
  )
}

export default AppButtonLogout
