import React from 'react';

const WALLS_SHAPES = {
  "descoberta": {
    1: "M515 355.515c88.944 0 161.155 72.211 161.155 161.155 0 32.447-9.61 62.667-26.138 87.968l-8.823-6.738c15.117-23.424 23.891-51.31 23.891-81.23 0-82.834-67.251-150.085-150.085-150.085v-11.07z",
    2: "M517.702 219.72c162.655 1.453 294.26 133.954 294.26 296.95 0 63.576-20.022 122.512-54.096 170.839l-9.737-7.367c32.529-46.286 51.629-102.666 51.629-163.472 0-156.361-126.298-283.456-282.34-284.748l.284-12.202z",
    3: "M517.47 69.821c245.141 1.631 443.68 201.177 443.68 446.729 0 98.921-32.221 190.376-86.749 264.456l-14.261-10.987c52.18-71.027 83.01-158.676 83.01-253.469 0-235.658-190.54-427.164-425.806-428.73l.126-17.999z"
  },
  "afetos": {
    1: "M640.536 598.912l8.809 6.746c-28.87 43.479-78.284 72.167-134.345 72.167-54.016 0-101.86-26.632-131.11-67.477l8.792-6.733c27.22 38.206 71.88 63.14 122.318 63.14 52.484 0 98.712-26.998 125.536-67.843z",
    2: "M281.183 679.16C332.651 753.043 418.219 801.428 515 801.428c96.466 0 181.793-48.072 233.321-121.56l9.679 7.45c-53.764 76.378-142.596 126.314-243 126.314-100.846 0-190.019-50.377-243.702-127.314l9.885-7.158z",
    3: "M164.071 763.625C241.744 873.5 369.759 945.29 514.41 945.29c140.912 0 266.038-68.127 344.211-173.216l14.13 11.159C791.267 892.494 661.041 963.29 514.41 963.29c-150.674 0-284.027-74.755-364.978-189.195l14.639-10.47z"
  },
  "causas": {
    1: "M513.787 355.52v11.07c-82.279.654-148.872 67.652-148.872 150.08 0 31.079 9.467 59.965 25.679 83.937l-8.81 6.729c-17.627-25.832-27.939-57.054-27.939-90.666 0-88.535 71.548-160.49 159.942-161.15z",
    2: "M513.409 219.713l-.059 12.204c-156.404.888-283.108 128.141-283.108 284.753 0 59.643 18.376 115.028 49.779 160.808l-9.936 7.088c-32.822-47.756-52.047-105.598-52.047-167.896 0-163.373 132.214-296.11 295.371-296.957z",
    3: "M511.282 69.821l.127 18C276.172 89.425 85.67 280.916 85.67 516.55c0 91.575 28.772 176.483 77.776 246.188l-14.67 10.427C97.681 700.534 67.67 612.019 67.67 516.55c0-245.527 198.5-445.058 443.612-446.729z"
  },
  "top": {
    1: "M518.812 363.583l-.422 101.008-10-.042.422-100.966h10z",
    2: "M518.812 363.583h-10l.581-139.293h10l-.581 139.293z",
    3: "M509.375 228.743L510 78.909l10 .042-.625 149.792h-10z"
  },
  "right": {
    1: "M649.157 598.489l-6.45 7.65-83.639-65.482 6.164-7.874 83.925 65.706z",
    2: "M758.301 683.939l-6.45 7.65-111.865-87.58 6.45-7.651 111.865 87.581z",
    3: "M752.217 679.175l125.975 98.628-6.164 7.874-126.261-98.851 6.45-7.651z"
  },
  "left": {
    1: "M382.095 599.841l82.824-59.619 5.842 8.116-82.903 59.676-5.763-8.173z",
    2: "M388.938 607.237l-111.157 80.014-5.763-8.173 111.157-80.015 5.763 8.174z",
    3: "M279.96 685.682l-120.328 86.615-5.762-8.173 120.327-86.615 5.763 8.173z"
  }
}

class CityWalls extends React.Component {
  renderWalls(wallsStats){
    let wallsToRender = []
    for (const [name, walls] of Object.entries(wallsStats)) {
      walls.forEach((wall, i) => {
        wall && wallsToRender.push(<path key={`${name}--${i}-wall`} d={WALLS_SHAPES[name][i+1]} />)
      });
    }
    return wallsToRender
  }

  render() {
    return (
      <g id="WALLS" transform="translate(-1.25764 -32.9576) scale(.99776)" fill="rgb(61,52,47)">
        {this.renderWalls(this.props.stats)}
      </g>
    )
  }
}

export default CityWalls;
