import React from 'react';
import Text from '../components/game/text.js'
import Title from '../components/game/title.js'
import AvatarCard from '../components/avatar/card.js'
import { TeacherDataContext } from '../providers/TeacherDataProvider.js'
import Loader from '../components/game/loader.js'
import { activateClass, completeMission } from '../middlewares/firebase.js'
import { CLASS_STATUS } from '../utils/constants.js'
import { MISSION_DESCRIPTIONS } from '../utils/copy.js'
import { parseGameVersion } from "../utils/funcs"
import Button from '../components/game/appButton.js'

class CrewStudents extends React.Component {
  static contextType = TeacherDataContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeMission: 0,
      selectedMission: 0,
      missionsWatcher: {},
      completeEnabled: true
    }
    this.completeButton = React.createRef()
  }

  componentDidMount() {
    if(this.context.classId) {
      activateClass(this.context.classId).then(() => {
        this.setState({
          loading: false
        })
      })
    }
    this.getActiveMission(this.context.activeClass.missions, true)

    this.setState({missionsWatcher: setInterval(() => {
      // Watch for changes on the activeMission
      this.getActiveMission(this.context.activeClass.missions)
      }, 1000)
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.missionsWatcher)
  }

  componentDidUpdate() {
    if(this.context.classID && !this.state.loading && this.context.activeClass.status === CLASS_STATUS.OPEN) {
      activateClass(this.context.classId).then(() => {
        this.setState({
          loading: false
        })
      })
    }
  }

  getActiveMission(missions, updateSelected) {
    if(missions){
      let newActiveMission = missions.findIndex(mission => mission.completed === false)
      this.setState({ activeMission: newActiveMission })
      if(updateSelected) {
        this.setState({ selectedMission: newActiveMission })
      }
    }
  }

  getMissionPercentage(value) {
    return parseInt(value * 100)
  }

  submitCompleteMission(index) {
    // Complete manually a mission and update the next active mission
    completeMission(this.context.classId, index)
    this.setState({ completeEnabled: false })
  }

  renderAvatars(activeClass, index) {
    if(activeClass !== null) {
      // Render the avatar defined by each student
      var avatarsEl = activeClass.students.sort((a, b) => a.studentNo - b.studentNo).map(function(student, key) {
        return (
          <AvatarCard
            key={key}
            modifiers="c-avatar-card--sm"
            game={parseGameVersion(student.version)}
            name={student.name}
            number={student.studentNo}
            verify={student.missions[index]}
          />
        )
      }, this)
      return avatarsEl
    }
  }

  renderFooter(activeClass) {
    if(activeClass !== null) {
      let mission = activeClass.missions[this.state.selectedMission]
      return(
        <div data-flout="align-center--" className="u-mb-4x">
          <progress
            className={`c-progress c-progress--lg ${mission.completed && "c-progress--completed"}`}
            max="100"
            value={this.getMissionPercentage(mission.progress)}
          >
            {this.getMissionPercentage(mission.progress)}%
          </progress>
          <p className="u-ml u-ts-1">{this.getMissionPercentage(mission.progress)}%</p>
        </div>
      )
    } else if(this.state.activeMission === 0 && this.state.selectedMission === 0) {
      return(
        <h3 className="c-title c-title--framed u-mb-2x">
          {window.location.origin}/turmas/{activeClass.url}
        </h3>
      )
    }
  }

  renderProgress(activeClass) {
    if(activeClass !== null && ('missions' in activeClass)) {
      // Show progress bar if there is missions data
      let mission = activeClass.missions[this.state.selectedMission]
      return(
        <div data-flout="align-center--">
          <progress
            className={`c-progress c-progress--lg ${mission.completed && "c-progress--completed"}`}
            max="100"
            value={this.getMissionPercentage(mission.progress)}
          >
            {this.getMissionPercentage(mission.progress)}%
          </progress>
          <p className="u-ml u-ts-1">{this.getMissionPercentage(mission.progress)}%</p>
        </div>
      )
    } else if(this.state.activeMission === 0 && this.state.selectedMission === 0) {
      // Show students login link if missions weren't created yet
      return(
        <a className="o-dashboard__text-link u-ts-2" href={`${window.location.origin}/turmas/${activeClass.url}`} target="_blank" rel="noreferrer">
          {window.location.origin}/turmas/{activeClass.url}
        </a>
      )
    }
  }

  renderCompleteButton(index) {
    if((index === 0 || index === 4 || index === 9 || index === 14)
      && (this.state.selectedMission === this.state.activeMission)
    ) {
      return (
        <Button
          ref={this.completeButton}
          wrapperModifiers="c-button--big u-mt"
          disabled={!this.state.completeEnabled}
          onClick={() => this.submitCompleteMission(index)}
        >
          Concluir Missão
        </Button>
      )
    }
  }

  prevMission() {
    if(this.state.selectedMission > 0) {
      this.setState({
        selectedMission: parseInt(this.state.selectedMission) - 1,
        completeEnabled: true
      })
    }
  }

  nextMission() {
    if(this.state.selectedMission < 14) {
      this.setState({
        selectedMission: parseInt(this.state.selectedMission) + 1,
        completeEnabled: true
      })
    }
  }

  disableNextMission() {
    // TO DO: Replace by a condition that check the last available mission
    if(this.state.selectedMission < this.state.activeMission) {
      return false
    } else {
      return true
    }
  }

  render() {
    if(this.state.loading){
      return <Loader />
    }

    return (
      <div className="o-app__bg-paper">
        <div className="o-app__frame o-app__frame--xl">
          <section className="o-view">
            <div data-flout="col-- align-center-- align-top@sm-- expand--" className="u-pt-2x u-ph-2x">
              <div data-flout="col-- align-center-- align-top@sm-- expand--" >
                <Title
                modifiers="c-title--sm c-title--dashboard c-title--w-100 u-mb-2x"
                content={`Ranking Turma - Nível ${this.state.activeMission + 1}`}
              />
              </div>
              <div data-flout="col-- align-center-- align-top@sm-- expand--" >
                <div className="o-grid u-12/12" data-flout="row-- align-center-- ">
                  <div className="o-grid__col u-6/12 u-3/12@sm u-mb">
                    <Button
                      wrapperModifiers={`c-button-wrapper--previous u-mb-0 u-mt u-mt-0@sm
                        ${parseInt(this.state.selectedMission) === 0 && "c-button--hidden"}
                      `}
                      onClick={() => this.prevMission()}
                    >
                      Missão Anterior
                    </Button>
                  </div>
                  <div className="o-grid__col u-6/12 u-3/12@sm u-mb" data-flitem="last@sm--">
                    <Button
                    wrapperModifiers={`c-button-wrapper--next u-mb-0 u-mt u-mt-0@sm
                      ${this.disableNextMission() && "c-button--hidden"}
                      `}
                      onClick={() => this.nextMission()}
                    >
                      Próxima Missão
                    </Button>
                  </div>
                  <div className="o-grid__col u-12/12 u-6/12@sm u-mb" data-flout="col-- align-center-- expand--">
                    <p className="c-title--secondary u-color-logos-black">{this.state.selectedMission + 1}ª Missão</p>
                  </div>
                </div>
              </div>

              <p className="u-ts-1 u-mt c-title--framed u-color-logos-black">
              {MISSION_DESCRIPTIONS[this.state.selectedMission + 1]}
              </p>
            </div>
            <div data-flout="align-center-- justify-center-- expand--" className="u-pb-2x">
              {this.renderAvatars(this.context.activeClass, this.state.selectedMission)}
            </div>
            <div data-flout="col-- align-center-- justify-center--" className="u-mb-2x">
              {this.renderProgress(this.context.activeClass)}
              {this.renderCompleteButton(this.state.selectedMission)}
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default CrewStudents
