import * as React from "react"

import amuletos from "../../assets/images/amulets/amuletos.svg"
import alpha from "../../assets/images/amulets/amuleto-alpha.svg"
import meraki from "../../assets/images/amulets/amuleto-meraki.svg"
import rosetta from "../../assets/images/amulets/amuleto-rosetta.svg"
import chimera from "../../assets/images/amulets/amuleto-chimera.svg"

function selectedCategory(category){

  //CHANGE THIS CASES TO LOGOS CHALLENGES TYPES
  switch (category) {
    //"descoberta" to "alpha" / "tutorial" eg.
    case "descoberta":
      return alpha
      break;
    //"dia-diferente" to "meraki" / "problema 0" / "small circle" eg.
    case "dia-diferente":
      return meraki
      break;
    //"accao" to "rosetta" / "problema 1" / "medium circle" eg.
    case "accao":
      return rosetta
      break;
    //"influencer" to "chimera" / "problema 2" / "large circle" eg.
    case "influencer":
      return chimera
      break;
    default:
      return amuletos
  }
}

function CategoryIcon(props) {
  return (
    <img src={selectedCategory(props.category)} width="48px" alt={props.category}/>
  )
}

export default CategoryIcon
