import * as React from "react"

function themeClass(modifiers){
  return `c-input-group  ${modifiers ? modifiers : ""}`
}

function selectOptions(options) {
  // Render the list of options provided as prop.options array
  var optionsEl = options.map(function(value) {
    return (
    <option value={value} key={value}>{value}</option>
  )
  })
  return optionsEl
}

function AppSelect(props) {
  return (
    <div className={themeClass(props.modifiers)}>
      <label className={`${props.label && "c-input-label"}`} htmlFor={props.id}>{props.label}</label>
      <div className="c-input-wrapper c-input-wrapper--select">
        <select className="c-input c-input--select" name={props.id} id={props.id} onChange={props.onChange} defaultValue="-">
          <option disabled value="-">{props.placeholder ? props.placeholder : "-"}</option>
          {selectOptions(props.options)}
        </select>
      </div>
    </div>
  )
}

export default AppSelect
